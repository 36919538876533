import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Tag, Select, Modal, message, Form, Button, Badge } from "antd";
import ReactStopwatch from "react-stopwatch";
import { connect } from "react-redux";
import { setUserActivityStatus, setBreakEventId } from "redux/actions/User";
import UserService from "services/UserService";
import { BREAK_EVENT_ID } from "redux/constants/User";
import { loadContactInfo } from "redux/actions/Crm";

const { Option } = Select;

const UserEvents = ({
  userInfo,
  userActivityStatus,
  breakEventId,
  setUserActivityStatus,
  setBreakEventId,
  startCall,
  currentCall,
  loadContactInfo,
  contactTraceData,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [selectForm] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [breakType, setBreakType] = useState("");
  const Events = [
    "Tea Break",
    "Lunch Break",
    "Briefing Break",
    "Training Break",
    "Feedback Break",
    "Comfort Break",
  ];
  const logEvent = (time) => {
    
    UserService.logUserEvent({
      userInfo: userInfo,
      eventName: breakType,
      id: breakEventId,
      logTime: time,
    }).then((res) => {
      setBreakEventId(res);
      sessionStorage.setItem(BREAK_EVENT_ID, res);
    });
  };

  const onOpen = () => {
    if (!startCall) {
      setBreakType("");
      setVisible(true);
    }
  };

  const handleOk = () => {
    if (userActivityStatus !== "onBreak") {
      if (!breakType) {
        message.error("Please Select a Break Event");
        return false;
      } else {
        setUserActivityStatus("onBreak");
        logEvent("Start");
      }
    } else {
      return false;
    }
  };

  const handleCancel = () => {
    setUserActivityStatus("active");
    setVisible(false);
    if (breakEventId) {
      logEvent("End");
    }
    selectForm.resetFields();
  };

  const goBackToCurrentCall = () => {
    if (startCall === "Outbound") {
      sessionStorage.setItem(
        "CONTACT_INFO",
        btoa(JSON.stringify({ id: currentCall }))
      );
      loadContactInfo({ id: currentCall });
    }

    history.push(`/app/crm/contact-detail`);
  };

  useEffect(() => {
    const breakEvent = sessionStorage.getItem(BREAK_EVENT_ID);
    if (breakEvent && breakEvent !== "undefined") {
      setVisible(true);
      setUserActivityStatus("onBreak");
    }
    return () => {
      setUserActivityStatus("active");
      setVisible(false);
      setBreakEventId("");
    };
  }, []);

  const onBreakChange = (value) => {
    setBreakType(value);
  };

  return (
    <>
      {startCall === "Outbound" || startCall === "Inbound" ? (
        <span
          className="callActiveStatus"
          style={{ color: "green", fontWeight: "bold" }}
        >
          {(location.pathname === "/app/crm/contact-detail" &&
            startCall === "Inbound") ||
          (currentCall === contactTraceData.id &&
            startCall === "Outbound" &&
            location.pathname === "/app/crm/contact-detail") ? (
            <Badge
              status="processing"
              color="#3F902B"
              text={<span className="badge_text">Live Call</span>}
            />
          ) : (
            <Button
              type="primary"
              className="px-3 btn-outline rounded border_radius"
              onClick={goBackToCurrentCall}
            >
              BACK TO CALL
            </Button>
          )}
        </span>
      ) : (
        <span
          className="callInactiveStatus"
          style={{ color: "grey", fontWeight: "bold" }}
        >
          NO CALL
        </span>
      )}

      {userActivityStatus === "active" ? (
        <Tag
          color="green"
          onClick={onOpen}
          style={{ marginTop: "24px", marginLeft: "20px", cursor: "pointer" }}
        >
          Active
        </Tag>
      ) : (
        <Tag
          color="red"
          onClick={onOpen}
          style={{ marginTop: "24px", marginLeft: "20px", cursor: "pointer" }}
        >
          Paused
        </Tag>
      )}

      <Modal
        title="Break Time"
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Break Over"
        okText="Start Break"
        width="350px"
        centered
        closable={false}
        maskClosable={false}
      >
        {userActivityStatus !== "onBreak" ? (
          <Form form={selectForm} autoComplete="off">
            <Form.Item name="breakSelection">
              <Select
                showSearch
                allowClear
                placeholder="Select Break Time"
                style={{ width: "80%" }}
                onChange={onBreakChange}
              >
                {Events.map((elm, i) => (
                  <Option key={i} value={elm} label={elm}>
                    {elm}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        ) : (
          <ReactStopwatch
            seconds={0}
            minutes={0}
            hours={0}
            render={({ formatted }) => {
              return (
                <div>
                  <h1 style={{ textAlign: "center" }}>{formatted}</h1>
                </div>
              );
            }}
          />
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = ({ user, auth, crm }) => {
  const { breakEventId, userActivityStatus } = user;
  const { userInfo } = auth;
  const { startCall, currentCall, contactTraceData } = crm;
  return {
    breakEventId,
    userInfo,
    userActivityStatus,
    startCall,
    currentCall,
    contactTraceData,
  };
};

export default connect(mapStateToProps, {
  setUserActivityStatus,
  setBreakEventId,
  loadContactInfo,
})(UserEvents);
