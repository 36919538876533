let BASE_URL;
if (window.location.hostname === "localhost") {
  BASE_URL = `http://localhost:3003/api`;
} else {
  BASE_URL = `/api`;
}

const AuthConstant = {
  SIGNIN_URL: `${BASE_URL}/auth/signin`,
  USER_PROFILE: `${BASE_URL}/auth/profile`,
  LOGIN_WITH_TOKEN_URL: `${BASE_URL}/auth/validate-token`,
  SIGNIN_OTP_URL: `${BASE_URL}/auth/signin-otp`,
  RESEND_OTP_URL: `${BASE_URL}/auth/resend-otp`,
  GOOGLE_SIGNIN_URL: `${BASE_URL}/auth/googleSignIn`,
  VICIDIAL_LOGIN_URL: `${BASE_URL}/auth/vicidial-login`,
  VICIDIAL_LOGOUT_URL: `${BASE_URL}/auth/vicidial-logout`,
  ROLE_LIST: `${BASE_URL}/auth/roles-list`,
  GROUP_LIST: `${BASE_URL}/auth/groups-list`,
  UPDATE_PASSWORD: `${BASE_URL}/auth/update-password`,
  CHANGE_PASSWORD: `${BASE_URL}/auth/update-old-password`,
};

export default AuthConstant;
