import HttpClient from "../utils/http-client";
import CrmConstant from "../constants/CrmConstant";
import { v4 as uuidv4 } from "uuid";
import utils from "utils";
import { checkIfAgent, decryptData } from "utils/user-manage";
import { SMARTDIAL_PUBLIC } from "redux/constants/Crm";

const CrmService = {
  contactList: (
    from,
    size,
    searchedParams,
    userInfo,
    sortFilters,
    searchCallType
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${CrmConstant.CONTACT_LIST_URL}`,
          {
            searchedParams: searchedParams,
            owner_grp_id: userInfo.owner_group,
            sortFilters: sortFilters,
            searchCallType: searchCallType,
            from: from,
            size: size,
            role: userInfo?.role,
          }
        );
        const contactlist = response.data.contactList;

        resolve(contactlist);
      } catch (error) {
        reject(error);
      }
    });
  },
  contactDetail: (id, loan_account_no, batch_no, customerid, cust_phone) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(
          `${CrmConstant.CONTACT_DETAIL_URL}?id=${id}&loan_account_no=${loan_account_no}&batch_no=${batch_no}&customerid=${customerid}&cust_phone=${cust_phone}`
        );
        const contactdetail = response.data;
        resolve(contactdetail);
      } catch (error) {
        reject(error);
      }
    });
  },

  callingContact: (
    calltype,
    userInfo,
    row,
    country_code,
    mobile,
    setViciCallLog,
    phone_type = false,
    std_code
  ) => {
    const calls_id = uuidv4();

    const data = {
      agent: userInfo.firstname + " " + userInfo.lastname,
      organisation: userInfo.organisation,
      username: userInfo.username,
      loan_account_no: row.loan_account_no,
      spoctoid: row.spoctoid,
      customerid: row.customerid,
      customer_name: row.full_name,
      batch_no: row.batch_no,
      agent_status: true,
      vicidialServerIp: userInfo.server_ip,
      calls_id: calls_id,
      country_code: country_code,
      std_code: std_code,
      destination_number: mobile,
      is_spocto_phone: phone_type,
      direction: calltype,
      phone_type: phone_type,
      vicidial_public: decryptData(sessionStorage.getItem(SMARTDIAL_PUBLIC)),
      portfolio:
        row?.standardized_product &&
        row?.bucket_type &&
        `${row?.standardized_product}-${row?.bucket_type}`,
    };
    return new Promise(async (resolve, reject) => {
      try {
        if (calltype === "Outbound") {
          const response = await HttpClient.post(
            `${CrmConstant.CALL_CONTACT}`,
            data
          );

          if (
            response &&
            response.data.smartdial &&
            response.data.smartdial.includes("SUCCESS") === true
          ) {
            setViciCallLog(response.data.smartdial);

            const callData = {
              response: response.data.smartdial,
              id: response.data.callloginsertid,
              calls_id: response.data.calls_id,
              calltype: calltype,
              mobile: mobile,
            };

            sessionStorage.setItem("call", JSON.stringify(callData));
          }
          resolve(response.data);
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  updateMultipleContacts: (data, docids) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${CrmConstant.CONTACT_UPDATE_URL}`,
          { data, docids }
        );
        const contactdetail = response.data;
        resolve(contactdetail);
      } catch (error) {
        reject(error);
      }
    });
  },
  disposeCall: (userInfo, contactTraceData, formValues, startCall) => {
    const callInfo = JSON.parse(sessionStorage.call);

    const callLogData = {
      id: contactTraceData.id,
      agent: userInfo.firstname + " " + userInfo.lastname,
      username: userInfo.username,
      loan_account_no: contactTraceData.loan_account_no
        ? contactTraceData.loan_account_no
        : formValues.account,
      spoctoid: contactTraceData.spoctoid,
      customerid: contactTraceData.customerid,
      customer_name: contactTraceData.full_name
        ? contactTraceData.full_name
        : formValues.name,
      customer_firstname: contactTraceData.first_name,
      customer_lastname: contactTraceData.last_name,
      batch_no: contactTraceData.batch_no,
      disposition: formValues.disposition,
      rank: formValues.rank,
      sub_disposition: formValues.sub_disposition,
      call_description: formValues?.description
        ? formValues?.description.replace(/[^\w]/g, " ")
        : "",
      ptp_committed_date: formValues.ptp_committed_date,
      ptp_committed_amount: utils.validateNumber(
        formValues.ptp_committed_amount
      ),
      settlement_amount: utils.validateNumber(formValues.settlement_amount),
      emi_amount: utils.validateNumber(formValues.emi_amount),
      cibil_amount: utils.validateNumber(formValues.cibil_amount),
      payment_date: formValues.payment_date,
      call_source: callInfo?.source ? callInfo.source : "spocto360",
      payment_amount: utils.validateNumber(formValues.payment_amount),
      payment_mode: formValues.payment_mode,
      lastInsertId: callInfo.id,
      reference_number: formValues.reference_number,
      no_of_installments: formValues.no_of_installments,
      calling_number: callInfo.mobile,
      portfolio:
        contactTraceData?.standardized_product &&
        contactTraceData?.bucket_type &&
        `${contactTraceData?.standardized_product}-${contactTraceData?.bucket_type}`,
      oid: contactTraceData?.oid,
    };

    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${CrmConstant.CALL_LOG_URL}`,
          callLogData
        );
        //If call is Inbound Activate Next Call and update Assigned User in Index.
        if (startCall === "Inbound") {
          HttpClient.get(
            `${CrmConstant.PAUSE_USER}?username=${userInfo.username}&vicidialServerIp=${userInfo.server_ip}&status='RESUME'&organisation=${userInfo.organisation}`
          );
        }

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getListOfFiles: (payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${CrmConstant.FILE_LIST_URL}`,
          payload
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addFileMappings: (values) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${CrmConstant.FILE_MAPPING_URL}`,
          values
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  hangupCall: (userInfo) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(
          `${CrmConstant.HANGUP_URL}?username=${userInfo.username}&vicidialServerIp=${userInfo.server_ip}&organisation=${userInfo.organisation}`
        );

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  callHistory: (accno, customerid, from, size) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(
          `${CrmConstant.CALL_HISTORY_URL}?accno=${accno}&customerid=${customerid}&from=${from}&size=${size}`
        );
        const callHistory = response.data;
        resolve(callHistory);
      } catch (error) {
        reject(error);
      }
    });
  },
  getNavSearch: (from, size, searchQuery) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(
          `${CrmConstant.NAV_SEARCH_URL}?from=${from}&size=${size}&searchQuery=${searchQuery}`
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getDashboardData: (userInfo, searchedParams, searchCallType) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${CrmConstant.DASHBOARD_DATA}`,
          {
            role: userInfo.role,
            searchedParams: searchedParams,
            owner_grp_id: userInfo.owner_group,
            searchCallType: searchCallType,
          }
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  updateContactDetail: (data, id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${CrmConstant.UPDATE_CONTACT_DETAIL}`,
          { data, id }
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  campaignDialMethod: (userInfo, campaignMethod) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (campaignMethod === "RATIO" && checkIfAgent(userInfo.role)) {
          await HttpClient.get(
            `${CrmConstant.PAUSE_USER}?username=${userInfo.username}&vicidialServerIp=${userInfo.server_ip}&status='RESUME'&organisation=${userInfo.organisation}`
          );
        }
        resolve(campaignMethod);
      } catch (error) {
        reject(error);
      }
    });
  },

  todoContactList: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${CrmConstant.TODO_CONTACT_LIST_URL}`,
          data
        );
        const todoList = response.data.contactList;
        resolve(todoList);
      } catch (error) {
        reject(error);
      }
    });
  },

  todoDashboard: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${CrmConstant.TODO_DASHBOARD_DATA_URL}`,
          data
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },

  sendSMS: (mobile, row) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(`${CrmConstant.SEND_SMS_URL}`, {
          mobile: mobile,
          spoctoid: row.spoctoid,
        });

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },

  uploadInVicidial: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${CrmConstant.UPLOAD_IN_VICIDIAL}`,
          data
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },

  distinctVicidialServer: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(
          `${CrmConstant.DISTINCT_VICIDIAL_SERVER}?owner_group=${data}`
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getLastDispositionListData: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${CrmConstant.GET_LAST_DISPOSITION_LIST}`,
          data
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getDispositionLifecycleData: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${CrmConstant.GET_DISPOSITION_LIFECYCLE}`,
          data
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getLastDisposition: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${CrmConstant.GET_LAST_DISPOSITION}`,
          data
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getBestDisposition: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${CrmConstant.GET_BEST_DISPOSITION}`,
          data
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },

  getAdvancedData: (spoctoid, customerid, loan_account_no) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(
          `${CrmConstant.GET_ADVANCED_DATA_URL}?spoctoid=${spoctoid}&customerid=${customerid}&loan_account_no=${loan_account_no}`
        );

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  checkIfWhatsappEnabled: (customerid) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(
          `${CrmConstant.CHECK_IF_WHATSAPP_ENABLED}?customerid=${customerid}`
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  sendWhatsappMessage: (mobile, row) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${CrmConstant.SEND_WHATSAPP_URL}`,
          {
            mobile: mobile,
            spoctoid: row.spoctoid,
          }
        );

        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
};

export default CrmService;
