import { Alert, Button, Col, Form, Input, message, Modal, Row } from "antd";
import { Footer } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import Flex from "components/shared-components/Flex";
import { AuthService } from "services";
import { connect } from "react-redux";
import { signOut } from "redux/actions/Auth";

const rules = {
  oldPassword: [
    {
      required: true,
      message: "Please input your password!",
    },
  ],
  password: [
    {
      required: true,
      message: "Please input your new password!",
    },
    {
      pattern:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,12}$/,
      message:
        "Password must contain min 8 & max 12 characters that include at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character without white spaces !",
    },
  ],
  confirm: [
    {
      required: true,
      message: "Please confirm your password!",
    },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (!value || getFieldValue("password") === value) {
          return Promise.resolve();
        }
        return Promise.reject("Passwords do not match!");
      },
    }),
  ],
};

const getFields = (heading) => [
  {
    name: "old_password",
    label: heading ? "One Time Password" : "Old Password",
    rule: rules.oldPassword,
  },
  {
    name: "password",
    label: "New Password",
    rule: rules.password,
  },
  {
    name: "confirm_password",
    label: "Confirm New Password",
    rule: rules.confirm,
  },
];

const ResetPasswordForm = ({ userInfo, signOut, heading, text }) => {
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showErrMsg, setShowErrMsg] = useState(false);
  const [errMsg, setErrMsg] = useState();

  useEffect(() => {
    setShowModal(userInfo.updatePassword);
  }, [userInfo]);

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, []);

  const onFinish = () => {
    setLoading(true);
    form
      .validateFields()
      .then(({ old_password, password, confirm_password }) => {
        AuthService.changePassword({
          old_password,
          password,
          confirm_password,
          username: userInfo.username,
        })
          .then((response) => {
            setLoading(false);
            setShowModal(false);
            message.success(response.message);
            form.resetFields();
            setTimeout(() => {
              message.destroy();
              signOut(userInfo.username, userInfo.server_ip, userInfo.role);
            }, 1000);
          })
          .catch((error) => {
            setLoading(false);
            setShowErrMsg(true);
            setErrMsg(error.message);
            clearError();
          });
      })
      .catch((err) => {
        setLoading(false);
        clearError();
      });
  };

  const getTitleForPasswordExpiry = () => {
    return (
      <div className="password_titles">
        <h3>{heading}</h3>
        <p>{text}</p>
      </div>
    );
  };

  const clearError = () => {
    setTimeout(() => {
      setShowErrMsg(false);
      setErrMsg();
    }, [4000]);
  };

  return (
    <Modal
      title="Change Password"
      open={showModal}
      centered
      footer={false}
      closable={false}
      className="change_password">
      <Row gutter={16}>
        {showErrMsg && (
          <Col xs={24} sm={24} md={24}>
            <Alert className="my-4" type="error" message={errMsg}></Alert>
          </Col>
        )}
      </Row>
      {getTitleForPasswordExpiry()}
      <Form
        layout="vertical"
        form={form}
        name="resetPasswordForm"
        autoComplete="off">
        <div className="container">
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24}>
              {getFields(heading).map((field, index) => (
                <Form.Item
                  key = {index}
                  name={field.name}
                  label={field.label}
                  rules={field.rule}>
                  <Input.Password key={index} placeholder={field.label} />
                </Form.Item>
              ))}
              <Footer>
                <Flex justifyContent="center">
                  <Button
                    style={{
                      borderRadius: "8px",
                      width: "30%",
                      minWidth: "fit-content",
                    }}
                    type="primary"
                    htmlType="submit"
                    onClick={() => onFinish()}
                    loading={loading}>
                    Set Password
                  </Button>
                </Flex>
              </Footer>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

const mapStateToProps = ({ auth }) => {
  const { userInfo, heading, text } = auth;
  return { userInfo, heading, text };
};

export default connect(mapStateToProps, { signOut })(ResetPasswordForm);
