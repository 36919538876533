import AuthConstant from "../constants/AuthConstant";
import HttpClient from "../utils/http-client";
import { AUTH_TOKEN } from "../redux/constants/Auth";
import { decryptData, encryptData } from "utils/user-manage";

const AuthService = {
  signin: (user) => {
    const { email, password } = user;
    const encryptedPassword = encryptData(password);
    const encryptedUser = {
      email: email,
      password: encryptedPassword,
    };

    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          AuthConstant.SIGNIN_URL,
          encryptedUser
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  loginWithToken: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          AuthConstant.LOGIN_WITH_TOKEN_URL
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getUserProfile: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(`${AuthConstant.USER_PROFILE}`);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },

  googleSignin: (user) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(AuthConstant.SIGNIN_URL, user);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  vicidialLogin: (payload) => {
    const username = payload.userInfo && payload.userInfo.username;
    const vicidialServerIp = payload.userInfo && payload.userInfo.server_ip;
    const organisation = payload.userInfo && payload.userInfo.organisation;
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          AuthConstant.VICIDIAL_LOGIN_URL,
          { username: username, vicidialServerIp: vicidialServerIp, organisation: organisation }
        );
        const decryptedData = JSON.parse(decryptData(response.data))
        resolve(decryptedData);
      } catch (error) {
        reject(error);
      }
    });
  },
  roleList: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(`${AuthConstant.ROLE_LIST}`);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  groupList: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(`${AuthConstant.GROUP_LIST}`);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  viciLogout: ({ vicidialServerIp }) => {
    const token = sessionStorage.getItem(AUTH_TOKEN);
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          AuthConstant.VICIDIAL_LOGOUT_URL,

          { vicidialServerIp: vicidialServerIp, token: token }
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  updatePassword: (data) => {
    const { username, password } = data;
    const payload = {
      username: username,
      password: password,
    };

    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          AuthConstant.UPDATE_PASSWORD,
          payload
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  changePassword: (data) => {
    const { username, old_password, password, confirm_password } = data;
    const payload = {
      username: username,
      old_password: old_password,
      password: password,
      confirm_password: confirm_password,
    };
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          AuthConstant.CHANGE_PASSWORD,
          payload
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
};

export default AuthService;
