import React, { lazy, Suspense, useEffect } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { loadCustomerList, loadUserProfile } from "redux/actions/Auth";
import {
  loadAssignedUserList,
  setUserAssignedCustomerDetail,
} from "redux/actions/User";
import { SharedService } from "services";
import socketIOClient from "socket.io-client";
import {
  setInboundCallData,
  setStartCall,
  setContactInfo,
  loadDispositionList,
  loadProductList,
  loadBucketList,
} from "redux/actions/Crm";
import { IS_CALL_ACTIVE } from "redux/constants/Crm";
import ViciLogin from "components/shared-components/Modal/ViciLogin";
import { AuthorizedUsers } from "utils/routes-util";
import { goToDetailPage } from "assets/functions/CRM";
import { checkIfAgent, giveCallingAccess } from "utils/user-manage";
import { getSocketEndPoint } from "utils/shared";

export const AppViews = (props) => {
  const {
    userInfo,
    loadCustomerList,
    loadAssignedUserList,
    setUserAssignedCustomerDetail,
    setStartCall,
    setInboundCallData,
    setContactInfo,
    loadDispositionList,
    loadProductList,
    loadBucketList,
    vicidialUserInfo,
    startCall,
  } = props;

  const history = useHistory();

  //Load Initial Data As Soon as Application is Loaded
  useEffect(() => {
    SharedService.dispositionList().then((res) => {
      loadDispositionList(res);
    });

    SharedService.productList().then((res) => {
      loadProductList(res);
    });

    SharedService.bucketList().then((res) => {
      loadBucketList(res);
    });
  }, []);

  useEffect(() => {
    const fetchInitialData = async () => {
      SharedService.customerList().then(({ customerList }) => {
        loadCustomerList(customerList);
        setUserAssignedCustomerDetail(customerList, userInfo);
      });
      SharedService.assignedUserList(userInfo).then((res) => {
        loadAssignedUserList(res);
      });
    };
    userInfo && fetchInitialData();
  }, [userInfo]);

  useEffect(() => {
    if (checkIfAgent(userInfo.role) && (!startCall || startCall === false)) {
      const socket = socketIOClient(getSocketEndPoint());
      socket.on(vicidialUserInfo && vicidialUserInfo.phone_login, (data) => {
        setStartCall("Inbound");
        setInboundCallData(data);

        goToDetailPage(
          data.id,
          data.loan_account_no,
          data.batch_no,
          data.customerid,
          9999,
          setContactInfo,
          history,
          data.mobile
        );

        const callData = {
          response: data.epoch,
          id: data.lastid,
          calls_id: data.calls_id,
          source: data.source,
          mobile: data.mobile,
        };

        sessionStorage.setItem("call", JSON.stringify(callData));
        sessionStorage.setItem(IS_CALL_ACTIVE, "Inbound");
      });
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to socket connection failed ${err}`);
      });
      socket.on("connect", () => {
        //console.log("front end socket id", socket.id);
      });
      //CLEAN UP THE EFFECT
      return () => socket.disconnect();
    }
  }, [
    userInfo.role,
    setContactInfo,
    setInboundCallData,
    vicidialUserInfo,
    startCall,
  ]);

  const PrivateRoutes = ({ component: Component, auth_roles, ...rest }) => {
    if (!userInfo) return false;
    return (
      <Route
        {...rest}
        render={(props) =>
          auth_roles.includes(userInfo.role) ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: `${APP_PREFIX_PATH}/crm`,
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  };

  return (
    <>
      <Suspense fallback={<Loading cover="content" />}>
        <Switch>
          <Route
            path={`${APP_PREFIX_PATH}/crm`}
            component={lazy(() => import(`./crm`))}
          />
          {AuthorizedUsers.APP.map((route) => {
            return (
              <PrivateRoutes
                key={route.url_path}
                path={`${APP_PREFIX_PATH}${route.url_path}`}
                auth_roles={route.roles}
                component={lazy(() => route.component)}
              />
            );
          })}
          <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/crm`} />
        </Switch>
      </Suspense>
      {giveCallingAccess(userInfo.role) &&
        userInfo.updatePassword != null &&
        !userInfo.updatePassword && <ViciLogin />}
    </>
  );
};

const mapStateToProps = ({ auth, crm }) => {
  const { userInfo, message, showMessage, vicidialUserInfo } = auth;
  const { startCall } = crm;
  return {
    userInfo,
    message,
    showMessage,
    vicidialUserInfo,
    startCall,
  };
};

export default React.memo(
  connect(mapStateToProps, {
    loadUserProfile,
    loadCustomerList,
    loadAssignedUserList,
    setUserAssignedCustomerDetail,
    setInboundCallData,
    setStartCall,
    setContactInfo,
    loadDispositionList,
    loadProductList,
    loadBucketList
  })(AppViews)
);
