import {
  SIGNIN,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SHOW_LOADING,
  SIGNIN_WITH_GOOGLE,
  SHOWOTPFORM,
  VICIDIAL_LOGIN,
  VICIDIAL_AUTHENTICATED,
  LOAD_CUSTOMER_LIST,
  CLOSE_VICIDIAL_POPUP,
  UPDATE_USER_INFO,
  LOAD_USER_PROFILE,
} from "../constants/Auth";

export const signIn = (user) => {
  return {
    type: SIGNIN,
    payload: user,
  };
};

export const loadUserProfile = ({ userInfo, heading, text }) => {
  return {
    type: LOAD_USER_PROFILE,
    userInfo,
    heading,
    text,
  };
};

export const authenticated = (user) => {
  return {
    type: AUTHENTICATED,
    user,
  };
};

export const showOtpForm = (status) => {
  return {
    type: SHOWOTPFORM,
    status,
  };
};

export const signOut = (username, vicidialServerIp, role) => {
  return {
    type: SIGNOUT,
    payload: { username, vicidialServerIp, role },
  };
};

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS,
  };
};

export const signInWithGoogle = (googleData) => {
  return {
    type: SIGNIN_WITH_GOOGLE,
    payload: googleData,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message,
  };
};

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

export const vicidialLogin = (userInfo, campaign, vicidialServerIp) => {
  return {
    type: VICIDIAL_LOGIN,
    payload: { userInfo, campaign, vicidialServerIp },
  };
};
export const vicidialAuthenticated = (viciUserInfo) => {
  return {
    type: VICIDIAL_AUTHENTICATED,
    viciUserInfo,
  };
};

export const loadCustomerList = (customerList) => {
  return {
    type: LOAD_CUSTOMER_LIST,
    customerList,
  };
};

export const closeVicidialPopup = (status) => {
  return {
    type: CLOSE_VICIDIAL_POPUP,
    status,
  };
};

export const updateUserInfo = (userInfo) => {
  return {
    type: UPDATE_USER_INFO,
    userInfo,
  };
};
