import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal, Select, message } from "antd";
import { vicidialLogin, closeVicidialPopup } from "redux/actions/Auth";
import { CrmService, SharedService } from "services";
import { giveCallingAccess } from "utils/user-manage";
import { IS_VICI_LOGGEDIN } from "redux/constants/Auth";

const ViciLogin = ({
  vicidialLogin,
  userInfo,
  isViciLoggedIn,
  closeVicidialPopup,
}) => {
  const [campaign, setCampaign] = useState("");
  const [campaignMethod, setCampaignMethod] = useState("");
  const [campaignList, setCampaignList] = useState("");
  const [vicidialError, setVicidialError] = useState("");

  const displayError = (msg) => {
    message.error(msg);
  };
  const handleOk = () => {
    if (!campaign) {
      displayError("Please Select Campaign");
    } else {
      vicidialLogin(userInfo, campaign);
      setTimeout(() => {
        CrmService.campaignDialMethod(userInfo, campaignMethod).then((res) => {
          closeVicidialPopup(false);
        });
      }, 1000);
    }
  };

  const handleCancel = () => {
    closeVicidialPopup(false);
    sessionStorage.setItem(IS_VICI_LOGGEDIN, false);
  };

  const onCampaignChange = (value, option) => {
    setCampaign(value);
    setCampaignMethod(option.dialmethod);
  };

  const { Option } = Select;

  useEffect(() => {
    const fetchInitialData = async () => {
      SharedService.campaignList(userInfo.server_ip, userInfo.organisation)
        .then((res) => {
          if (res.message) {
            setVicidialError(res.message);
          } else {
            setCampaignList(res);
          }
        })
        .catch((err) => err?.message && setVicidialError(err.message));
    };
    giveCallingAccess(userInfo.role) && fetchInitialData();

    return () => {
      closeVicidialPopup({});
    };
  }, []);

  return (
    <>
      <Modal
        title="SmartDial Campaign Selection"
        open={isViciLoggedIn}
        onOk={handleOk}
        onCancel={handleCancel}
        width="350px"
        centered
        closable={false}
      >
        {campaignList.length !== 0 ? (
          <Select
            showSearch
            allowClear
            placeholder="Select Campaign"
            optionFilterProp="label"
            style={{ width: "80%" }}
            onChange={onCampaignChange}
          >
            {campaignList.length !== 0 &&
              campaignList.map((elm, i) => (
                <Option
                  dialmethod={elm?.dial_method || "MANUAL"}
                  key={i}
                  value={elm.campaign_id}
                  label={elm.campaign_id}
                >
                  {elm.campaign_id}
                </Option>
              ))}
          </Select>
        ) : (
          <h4>{vicidialError}</h4>
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = ({ auth }) => {
  const { userInfo, message, showMessage, isViciLoggedIn } = auth;
  return {
    userInfo,
    message,
    showMessage,
    isViciLoggedIn,
  };
};

export default connect(mapStateToProps, {
  vicidialLogin,
  closeVicidialPopup,
})(ViciLogin);
