let BASE_URL;
if (window.location.hostname === "localhost")
  BASE_URL = `http://localhost:3003/api`;
else {
  BASE_URL = `/api`;
}

const CrmConstant = {
  CONTACT_LIST_URL: `${BASE_URL}/crm/contact-list`,
  CONTACT_DETAIL_URL: `${BASE_URL}/crm/contact-detail`,
  CONTACT_UPDATE_URL: `${BASE_URL}/crm/update-contact-list`,
  CALL_LOG_URL: `${BASE_URL}/crm/create-call-log`,
  CALL_HISTORY_URL: `${BASE_URL}/crm/call-history`,
  DASHBOARD_DATA: `${BASE_URL}/crm/dashboard`,
  NAV_SEARCH_URL: `${BASE_URL}/crm/nav-search`,
  UPDATE_CONTACT_DETAIL: `${BASE_URL}/crm/update-contact-detail`,
  TODO_CONTACT_LIST_URL: `${BASE_URL}/crm/todo-contact-list`,
  TODO_DASHBOARD_DATA_URL: `${BASE_URL}/crm/todo-dashboard`,
  GET_ADVANCED_DATA_URL: `${BASE_URL}/crm/get-advanced-data`,
  SEND_SMS_URL: `${BASE_URL}/integration/send-sms`,
  CHECK_IF_WHATSAPP_ENABLED: `${BASE_URL}/integration/checkif-whatsapp-enabled`,
  SEND_WHATSAPP_URL: `${BASE_URL}/integration/send-whatsapp-message`,

  CALL_CONTACT: `${BASE_URL}/vicidial/calling-contact`,
  PAUSE_USER: `${BASE_URL}/vicidial/pause-user`,
  HANGUP_URL: `${BASE_URL}/vicidial/hangup-contact`,
  UPLOAD_IN_VICIDIAL: `${BASE_URL}/vicidial/upload-contact-list`,
  DISTINCT_VICIDIAL_SERVER: `${BASE_URL}/vicidial/distinct-server`,
  GET_LAST_DISPOSITION_LIST: `${BASE_URL}/shared/list-interactions`,
  GET_DISPOSITION_LIFECYCLE: `${BASE_URL}/crm/get-disposition-lifecycle`,
  GET_BEST_DISPOSITION: `${BASE_URL}/shared/get-best-disposition`,
  GET_LAST_DISPOSITION: `${BASE_URL}/shared/get-last-disposition`,
 
  
  FILE_IMPORT_URL: `${BASE_URL}/file/import-file`,
  FILE_LIST_URL: `${BASE_URL}/file/get-file-list`,
  FILE_MAPPING_URL: `${BASE_URL}/file/add-file-mappings`,
  ADD_NUMBER_URL: `${BASE_URL}/file/add-numbers`,
};

export default CrmConstant;
