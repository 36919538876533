export const AuthorizedUsers = {
  APP: [
    {
      key: "users",
      url_path: "/users",
      component: import("../views/app-views/users"),
      roles: ["Super Admin", "Team Leader", "Supervisor"],
    },
  ],
  CRM: [
    {
      key: "todos",
      url_path: "/todos",
      component: import("../views/app-views/crm/todos"),
      roles: [
        "Agent",
        "Inbound Agent",
        "Team Leader",
        "Supervisor",
        "Admin",
        "Department Head",
        "Super Admin",
      ],
    },
    {
      key: "number-inventory",
      url_path: "/number-inventory",
      component: import("../views/app-views/crm/number-inventory"),
      roles: ["Supervisor", "Admin", "Department Head", "Super Admin"],
    },
    {
      key: "reports",
      url_path: "/reports",
      component: import("../views/app-views/crm/reports"),
      roles: [
        "Team Leader",
        "Supervisor",
        "Admin",
        "Department Head",
        "Super Admin",
      ],
    },
    {
      key: "data-administration",
      url_path: "/data-administration",
      component: import("../views/app-views/crm/data-administration"),
      roles: [
        "Team Leader",
        "Supervisor",
        "Admin",
        "Department Head",
        "Super Admin",
      ],
    },
    {
      key: "contact-detail",
      url_path: "/contact-detail",
      component: import("../views/app-views/crm/contact-detail"),
      roles: [
        "Agent",
        "Inbound Agent",
        "Team Leader",
        "Supervisor",
        "Admin",
        "Department Head",
        "Super Admin",
      ],
    },
  ],
  DATA_ADMINISTRATION: [
    {
      key: "file-management",
      url_path: "/file-management",
      component: import(
        "../views/app-views/crm/data-administration/file-management"
      ),
      roles: [
        "Team Leader",
        "Supervisor",
        "Admin",
        "Department Head",
        "Super Admin",
      ],
    },
    {
      key: "under-construction",
      url_path: "/under-construction",
      component: import(
        "../views/app-views/crm/data-administration/UnderConstruction"
      ),
      roles: [
        "Team Leader",
        "Supervisor",
        "Admin",
        "Department Head",
        "Super Admin",
      ],
    },
  ],
  REPORTS: [
    {
      key: "apr",
      url_path: "/apr",
      component: import("../views/app-views/crm/reports/APR"),
      roles: [
        "Team Leader",
        "Supervisor",
        "Admin",
        "Department Head",
        "Super Admin",
      ],
    },
    {
      key: "call-log",
      url_path: "/call-log",
      component: import("../views/app-views/crm/reports/CallLog"),
      roles: [
        "Team Leader",
        "Supervisor",
        "Admin",
        "Department Head",
        "Super Admin",
      ],
    },

    {
      key: "unassign-batch",
      url_path: "/unassign-batch",
      component: import("../views/app-views/crm/reports/UnassignBatch"),
      roles: ["Supervisor", "Admin", "Department Head", "Super Admin"],
    },
    {
      key: "vicidial-list-upload",
      url_path: "/vicidial-list-upload",
      component: import("../views/app-views/crm/reports/ViciDialListUpload"),
      roles: [
        "Supervisor",
        "Admin",
        "Department Head",
        "Super Admin",
        "Team Leader"
      ],
    },
  ],
  NUMBER_INVENTORY: [
    {
      key: "outbound",
      url_path: "/outbound",
      component: import("../views/app-views/crm/number-inventory/outbound"),
      roles: ["Supervisor", "Admin", "Department Head", "Super Admin"],
    },
    {
      key: "inbound",
      url_path: "/inbound",
      component: import("../views/app-views/crm/number-inventory/inbound"),
      roles: ["Supervisor", "Admin", "Department Head", "Super Admin"],
    },
  ],
};
