import React from "react";
import { connect } from "react-redux";
import { Select, Form } from "antd";
import { getDispositionList } from "utils/shared";

const { Option } = Select;

const DispositionDropdown = ({
  dispositionlist,
  selectedDisposition,
  mode,
  onChangefunction,
  placeholder,
  rules,
  label,
  name,
  searchCallType,
}) => {
  let list =
    label == "Sub-Disposition"
      ? selectedDisposition
      : getDispositionList(dispositionlist);

  return (
    <>
      <Form.Item
        name={name ? name : "disposition"}
        label={label ? label : ""}
        rules={rules ? rules : ""}
      >
        <Select
          showSearch
          placeholder={placeholder}
          optionFilterProp="label"
          size="medium"
          mode={mode}
          onChange={onChangefunction}
          disabled={searchCallType === "Inbound" ? true : false}
        >
          {name == "sub_disposition"
            ? list &&
              list.map((elm) => (
                <Option key={elm} value={elm} label={elm}>
                  {elm}
                </Option>
              ))
            : list &&
              list.map((elm) => (
                <Option key={elm.value} value={elm.value} label={elm.label}>
                  {elm.label}
                </Option>
              ))}
        </Select>
      </Form.Item>
    </>
  );
};

const mapStateToProps = ({ crm }) => {
  const { dispositionlist } = crm;
  return { dispositionlist };
};

export default connect(mapStateToProps)(DispositionDropdown);
