import HttpClient from "../utils/http-client";
import UserConstant from "constants/UserConstant";
import CrmConstant from "constants/CrmConstant";

const UserService = {
  addUser: (values) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(`${UserConstant.ADD_USER}`, {
          userDetails: values,
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addRole: (values) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${UserConstant.ADD_ROLE}`,
          values
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addGroup: (values) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${UserConstant.ADD_GROUP}`,
          values
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  editUser: (values, userInfo) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(`${UserConstant.EDIT_USER}`, {
          data: values,
          userInfo: { username: userInfo?.username },
        });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },

  userList: (from, size, searchedParams, owner_group) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${UserConstant.USER_LIST}?from=${from}&size=${size}`,
          { searchedParams: searchedParams, owner_group }
        );
        resolve(response.data.users);
      } catch (error) {
        reject(error);
      }
    });
  },
  logUserEvent: ({ userInfo, eventName, id, logTime }) => {
    const data = {
      eventName,
      id,
      logTime,
      product: userInfo?.product,
      bucket: userInfo?.bucket,
      customerid: userInfo?.customerid,
    };
    
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${UserConstant.LOG_USER_EVENT}`,
          data
        );
        if (logTime === "Start" && eventName !== "Login") {
          HttpClient.get(
            `${CrmConstant.PAUSE_USER}?vicidialServerIp=${userInfo.server_ip}&status='PAUSE'&organisation=${userInfo.organisation}`
          );
        } else if (logTime === "End" && eventName !== "Login") {
          HttpClient.get(
            `${CrmConstant.PAUSE_USER}?vicidialServerIp=${userInfo.server_ip}&status='RESUME'&organisation=${userInfo.organisation}`
          );
        }

        resolve(response.data.id);
      } catch (error) {
        reject(error);
      }
    });
  },
  roleList: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(UserConstant.ROLE_LIST);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  groupList: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(UserConstant.GROUP_LIST);
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
};

export default UserService;
