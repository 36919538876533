import {
  LOAD_ASSIGNED_USER_LIST,
  SET_USER_ACTIVITY_STATUS,
  SET_USER_ASSIGNED_CUSTOMER_DETAIL,
  SET_BREAK_EVENT_ID,
  SET_SMARTDIAL_IP,
  UPDATE_ASSIGNED_USER_LIST
} from "../constants/User";

export const loadAssignedUserList = (assignedList) => {
  return {
    type: LOAD_ASSIGNED_USER_LIST,
    assignedList,
  };
};

export const updateAssignedUserList = (list) => {
  return {
    type: UPDATE_ASSIGNED_USER_LIST,
    list,
  };
};
export const setUserActivityStatus = (status) => {
  return {
    type: SET_USER_ACTIVITY_STATUS,
    status,
  };
};
export const setUserAssignedCustomerDetail = (customerDetail, userInfo) => {
  return {
    type: SET_USER_ASSIGNED_CUSTOMER_DETAIL,
    payload: {
      customerDetail: customerDetail,
      customerid: userInfo.customerid,
      role: userInfo.role,
    },
  };
};

export const setBreakEventId = (breakEventId) => {
  return {
    type: SET_BREAK_EVENT_ID,
    breakEventId,
  };
};

export const setSmartdialIP = (ip) => {
  return {
    type: SET_SMARTDIAL_IP,
    ip,
  };
};
