export const getFormattedTime = (value) => {
  // return value ? new Date(value).toLocaleString() : "";
  return new Date(value).toLocaleTimeString("en-GB", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
};

export const formatDate = (input) => {
  var datePart = input.match(/\d+/g),
    year = datePart[0],
    month = datePart[1],
    day = datePart[2];

  return day + "/" + month + "/" + year;
};

export const getFormattedURL = (link) => {
  let formattedLink = link.replace(
    /https:\/\/spocto-cms.s3.ap-south-1.amazonaws.com\/voice\//g,
    " "
  );
  formattedLink = formattedLink.split(" ");
  for (let i = 0; i < formattedLink.length; i++) {
    if (
      formattedLink[i] === "" ||
      formattedLink[i] === " " ||
      formattedLink[i] === null ||
      formattedLink[i] === "null"
    ) {
      formattedLink.splice(i, 1);
    }
  }
  return formattedLink;
};

export const getLogoDynamically = (customerid, navCollapsed) => {
  if (navCollapsed) {
    switch (customerid) {
      case 2525:
        return "img/logos/small/emirates_logo_short.jpg";
      default:
        return "img/logos/small/spocto360_logo.png";
    }
  } else {
    switch (customerid) {
      case 2525:
        return "img/logos/large/Emirates_NBD_logo.png";
      default:
        return "img/logos/large/spocto360_logo.png";
    }
  }
};

export const randomColorGenerator = () => {
  const characters = "0123456789ABCDEF";
  let color = "#";

  for (let i = 0; i < 6; i++) {
    color += characters[Math.floor(Math.random() * 16)];
  }

  return color;
};

export const maskLoanAccountNo = (price) => {
  let last4Digits = price.slice(-4);
  let maskedAccountNumber;
  let lengthX = "XXXX";

  maskedAccountNumber = lengthX + last4Digits;

  return maskedAccountNumber;
};

export const addComasToAmount = (amount, currency) => {
  if (currency === "AED") {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    amount = String(amount);

    let [preDecimal, postDecimal] = amount.split(".");
    let structuredAmount = "";

    for (let i = preDecimal.length - 1; i >= 0; i--) {
      structuredAmount += preDecimal[i];
      if (preDecimal.length % 2 !== 0) {
        if (i !== preDecimal.length - 1 && i % 2 === 0) structuredAmount += ",";
      } else {
        if (i !== preDecimal.length - 1 && i % 2 !== 0) structuredAmount += ",";
      }
    }

    if (preDecimal.length % 2 !== 0)
      structuredAmount = structuredAmount.slice(0, structuredAmount.length - 1);

    structuredAmount = structuredAmount.split("").reverse().join("");

    if (!postDecimal) postDecimal = "00";
    else if (postDecimal.length === 1) postDecimal += "0";

    if (amount === "0.00") return amount;
    else return (structuredAmount = structuredAmount + "." + postDecimal);
  }
};

export const formatFileSize = (size) => {
  if (size >= 104857.6)
    return `${Math.round((size / 1048576 + Number.EPSILON) * 100) / 100} mb`;
  else if (size >= 102.4)
    return `${Math.round((size / 1024 + Number.EPSILON) * 100) / 100} kb`;
  else return `${size} bytes`;
};

export const formatAmount = (amount, currency) => {
  if (amount >= 1000000 && currency === "AED") {
    const millionsValue = amount / 1000000;
    const preciseMillions = (Math.floor(millionsValue * 100) / 100).toFixed(5);
    const formattedMillionsValue = preciseMillions.replace(/\.?0*$/, ""); // Remove trailing zeros
    return `${currency} ${formattedMillionsValue} Mil`;
  } else if (amount >= 1000 && currency === "AED") {
    const thousandsValue = amount / 1000;
    const formattedThousandsValue = (
      Math.floor(thousandsValue * 100) / 100
    ).toFixed(2);
    return `${currency} ${formattedThousandsValue} K`;
  } else if (amount >= 10000000) {
    return `${currency} ${
      Math.round((amount / 10000000 + Number.EPSILON) * 100) / 100
    } Cr`;
  } else if (amount >= 100000) {
    return `${currency} ${
      Math.round((amount / 100000 + Number.EPSILON) * 100) / 100
    } L`;
  } else if (amount >= 1000) {
    return `${currency} ${
      Math.round((amount / 1000 + Number.EPSILON) * 100) / 100
    } K`;
  }
};

export const getFormattedDateTime = (datetime) => {
  if (datetime) {
    let localstring = new Date(datetime).toLocaleTimeString("en-GB", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });

    let finalDate = localstring ? localstring.split(",") : null;
    let formatted = finalDate ? finalDate[0] : null;
    return formatted;
  }
};

export const getFormattedDashboardTitle = (title) => {
  let data = title.length > 21 ? title.split(" ") : title;
  let final_data = data;
  if (title == "FULLY PAID (Confirmed by bank)") {
    data = ["FULLY PAID", "(Confirmed by bank)"];
    final_data = data[0] + "<br/>" + data[1];
  } else if (title == "PARTIALLY PAID (Confirmed by bank)") {
    data = ["PARTIALLY PAID", "(Confirmed by bank)"];
    final_data = data[0] + "<br/>" + data[1];
  } else if (title == "SITUATIONAL NOT CONTACTABLE") {
    data = ["SITUATIONAL", "NOT CONTACTABLE"];
    final_data = data[0] + "<br/>" + data[1];
  } else {
    final_data = data + "<br/> &nbsp;";
  }

  return final_data;
};
