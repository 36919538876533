export const LOAD_AGENT_LIST = 'LOAD_AGENT_LIST'; 
export const LOAD_ASSIGNED_USER_LIST = 'LOAD_ASSIGNED_USER_LIST';
export const LOGIN_EVENT_ID = 'LOGIN_EVENT_ID';
export const BREAK_EVENT_ID = 'BREAK_EVENT_ID';
export const SET_USER_ACTIVITY_STATUS = 'SET_USER_ACTIVITY_STATUS';
export const SET_USER_ASSIGNED_CUSTOMER_DETAIL = 'SET_USER_ASSIGNED_CUSTOMER_DETAIL';
export const SET_BREAK_EVENT_ID = 'SET_BREAK_EVENT_ID';
export const SET_SMARTDIAL_IP = 'SET_SMARTDIAL_IP';
export const UPDATE_ASSIGNED_USER_LIST = 'UPDATE_ASSIGNED_USER_LIST'

