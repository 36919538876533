import CryptoJS from "crypto-js";
import { spoctoPrivKey } from "constants/EncryptConstant";

export function groupManagement(userInfo, assignedUserList) {
  switch (userInfo.role) {
    case "Agent":
      return userInfo.username;
    case "Team Leader":
      return assignedUserList;
    case "Supervisor":
      return assignedUserList;
    case "Department Head":
      return assignedUserList;
    default:
      return "";
  }
}

export function giveCallingAccess(role) {
  if (role === "Agent" || role === "Inbound Agent" || role === "Team Leader") {
    return true;
  } else {
    return false;
  }
}

export function checkIfAgent(role) {
  if (role === "Agent" || role === "Inbound Agent") {
    return true;
  } else {
    return false;
  }
}

export function checkIfWrongNumber(wrong_numbers, mobile, disposition) {
  const unSubscribeCalls = ["CLOSED", "STOP_FOLLOWUP", "DEALLOCATED"];
  if (
    (wrong_numbers && wrong_numbers.includes(mobile)) ||
    (disposition && unSubscribeCalls.includes(disposition))
  )
    return true;
  else return false;

}

export const encryptData = (data) => {
  if (!data) return false;
  const encryptedData = CryptoJS.AES.encrypt(data, spoctoPrivKey).toString();
  return encryptedData;
};

export const decryptData = (encryptedData) => {
  if (!encryptedData) return false;
  const decryptedData = CryptoJS.AES.decrypt(encryptedData, spoctoPrivKey);
  return decryptedData.toString(CryptoJS.enc.Utf8);
};
