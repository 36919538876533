import HttpClient from "../utils/http-client";
import ReportsConstant from "constants/ReportsConstant";

const ReportsService = {
  generateAprReport: (date_range, owner_group, role) => {
    const data = {
      date_range: date_range,
      owner_group: owner_group,
      role: role,
    };
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${ReportsConstant.APR_REPORT}`,
          data
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getAprList: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${ReportsConstant.APR_REPORT_LIST}`,
          data
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  downloadReport: (
    customerInfo,
    searchedParams,
    userInfo,
    assignedUserList
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${ReportsConstant.REPORT_DOWNLOAD}`,
          {
            customerInfo: customerInfo,
            searchedParams: searchedParams,
            userInfo: userInfo,
            assignedUserList: assignedUserList,
          }
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getContactListReport: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${ReportsConstant.REPORT_LIST_URL}`,
          data
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  generateCallLogReport: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${ReportsConstant.CALL_LOG_REPORT_URL}`,
          data
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getCallLogReportList: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${ReportsConstant.CALL_LOG_LIST_URL}`,
          data
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  createUnassignBatchLog: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          ReportsConstant.UNASSIGN_BATCH,
          data
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getUnassignBatchLog: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${ReportsConstant.UNASSIGN_BATCH_LOGS}`,
          data
        );
        resolve(response.data.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getListUpload:(data) =>{
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${ReportsConstant.GET_VICIDIAL_LIST_UPLOAD}`,
          data
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  downloadSampleFiles: (report_type) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(
          `${ReportsConstant.DOWNLOAD_SAMPLE_FILES}?file_type=${report_type}`
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
};

export default ReportsService;
