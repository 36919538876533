//This includes all the shared functions across Crm Application
import { success, error } from "components/shared-components/Modal";
import { CrmService } from "services";
import { IS_CALL_ACTIVE } from "redux/constants/Crm";
import { addComasToAmount } from "../MiscFunctions";
import utils from "utils";

export const callContactErrorChecking = (
  calltype,
  res,
  mobile,
  setStartCall,
  setCurrentCall,
  id,
  phone_type
) => {
  
  if (calltype === "Outbound") {
    if (res && res.smartdial && res.smartdial.includes("SUCCESS") === true) {
      setCurrentCall(id);
      success("Agent Alert", `Placing a Call to: ${mobile} `);
      setStartCall(calltype);
      sessionStorage.setItem("type", phone_type);
      sessionStorage.setItem("currentCall", id);
      sessionStorage.setItem(IS_CALL_ACTIVE, "Outbound");
    } else {
      error(res.smartdial || res);
    }
  } else {
    success("Agent Alert", `Placing a Call to: ${mobile} `);
  }
};

export const fetchContactList = (
  from,
  size,
  searchedParams,
  sortFilters,
  userInfo,
  searchCallType
) => {
  return new Promise((resolve, reject) => {
    CrmService.contactList(
      from,
      size,
      searchedParams,
      userInfo,
      sortFilters,
      searchCallType
    )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => reject(error));
  });
};

export const fetchTodoList = (payload) => {
  return new Promise((resolve, reject) => {
    CrmService.todoContactList(payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => reject(error));
  });
};

export const goToDetailPage = (
  id,
  loan_account_no,
  batch_no,
  customerid,
  index,
  setContactInfo,
  history,
  cust_phone
) => {
  const data = {
    id,
    loan_account_no,
    batch_no,
    customerid,
    index,
    cust_phone,
  };

  sessionStorage.setItem("CONTACT_INFO", btoa(JSON.stringify(data)));
  setContactInfo(data);
  history.push(`/app/crm/contact-detail`);
};

export const isToday = (date) => {
  let smsDate;
  const currentDate = new Date();

  if (typeof date === "string")
    smsDate = new Date(date.toString().split("Z")[0]);
  else smsDate = new Date(date);

  const isToday = currentDate.getDate() === smsDate.getDate();
  return isToday;
};

export const checkSmsMaxIntensity = (
  sms_sent_count,
  max_intensity,
  sms_sent_date
) => {
  if (sms_sent_count >= max_intensity && isToday(sms_sent_date)) {
    return true;
  } else {
    return false;
  }
};

export const findValue = (elm, contactTraceData, regionConfig) => {
  if (elm.type === "date" && contactTraceData[elm.value]) {
    return utils.getFormatedDate(contactTraceData[elm.value]);
  } else if (elm.type === "amount" && contactTraceData[elm.value]) {
    return `${regionConfig.currency} ${addComasToAmount(
      contactTraceData[elm.value],regionConfig.currency
    )}`;
  } else if (
    elm.type === "number" &&
    contactTraceData[elm.value] &&
    elm.value.includes("_dpd")
  ) {
    return `${contactTraceData[elm.value]} days`;
  } else {
    return contactTraceData[elm.value] || "-";
  }
};
