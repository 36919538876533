import React from "react";
import { Form, Input, DatePicker, Select, InputNumber } from "antd";
import moment from "moment";

const { Option } = Select;
let selectOptions = [];

export const DynamicInput = ({ elm }) => {
  let input;
  selectOptions = [];
  elm.type === "select" &&
    elm.options.forEach((elm, i) => {
      selectOptions.push(
        <Option key={elm} value={elm}>
          {elm}
        </Option>
      );
    });

  switch (elm.type) {
    case "text":
      input = (
        <Form.Item name={elm.name} label={elm.label} rules={elm.rule}>
          <Input
            placeholder={`Please enter ${elm.name}`}
            onKeyPress={(event) => {
              if (
                !/^[0-9]*\.?[0-9]*$/.test(event.key) &&
                elm.name !== "reference_number"
              ) {
                event.preventDefault();
              }
            }}
            maxLength={elm.name === "reference_number" ? 100 : 9}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
          />
        </Form.Item>
      );
      break;
    case "date":
      input = (
        <Form.Item name={elm.name} label={elm.label} rules={elm.rule}>
          <DatePicker
            disabledDate={(current) => {
              if (elm.disabledFutureDate)
                return current && current.valueOf() >= Date.now();
              else if (elm.disabledPastDate)
                return moment().add(-1, "days") >= current;
              else return "";
            }}
            picker="date"
            format={"YYYY-MM-DD"}
          />
        </Form.Item>
      );
      break;
    case "datetime":
      input = (
        <Form.Item name={elm.name} label={elm.label} rules={elm.rule}>
          <DatePicker
            showTime
            disabledDate={(current) => {
              if (elm.disabledFutureDate)
                return current && current.valueOf() >= Date.now();
              else if (elm.disabledPastDate)
                return moment().add(-1, "days") >= current;
              else return "";
            }}
            format="YYYY-MM-DD HH:mm:ss"
          />
        </Form.Item>
      );
      break;
    case "select":
      input = (
        <Form.Item name={elm.name} label={elm.label} rules={elm.rule}>
          <Select placeholder="Select an option" allowClear>
            {selectOptions}
          </Select>
        </Form.Item>
      );
      break;
    // case "number":
    //   input = (
    //     <Form.Item name={elm.name} label={elm.label} rules={elm.rule}>
    //       <InputNumber min={elm.min} max={elm.max} />
    //     </Form.Item>
    //   );
    //   break;
    case "number":
      input = (
        <Form.Item name={elm.name} label={elm.label} rules={elm.rule}>
          <InputNumber
            min={elm.min}
            max={elm.max}
            maxLength={elm.name === "no_of_installments" ? 3 : 40}
            onKeyPress={(event) => {
              if (
                !/^[0-9]*$/.test(event.key) &&
                elm.name === "no_of_installments"
              ) {
                event.preventDefault();
              }
            }}
          />
        </Form.Item>
      );
      break;
    default:
      input = null;
  }
  return input;
};
