import React from "react";
import { Menu, Dropdown, Avatar, Tooltip } from "antd";
import { connect } from "react-redux";
import { LogoutOutlined, EditOutlined } from "@ant-design/icons";
import { signOut } from "redux/actions/Auth";
import { useHistory } from "react-router-dom";

export const NavProfile = ({
  userInfo,
  signOut,
}) => {
  const history = useHistory();
  const profileImg = "img/avatars/profile.png";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">
              {userInfo && `${userInfo.firstname} ${userInfo.lastname}`}
            </h4>
            <span className="text-muted">{`${userInfo?.role}`}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item
            key={1}
            onClick={(e) => signOut(userInfo?.username, userInfo?.server_ip, userInfo?.role)}
          >
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
          {
            userInfo.role === 'Super Admin' &&
            <Menu.Item
              key={2}
              onClick={() => history.push(`/app/users`)}
            >
              <span>
                <EditOutlined className="mr-3" />
                <span className="font-weight-normal">Change Password</span>
              </span>
            </Menu.Item>
          }
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key={1}>
          <Tooltip placement="bottom" title="User Profile">
            <Avatar src={profileImg} />
          </Tooltip>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

const mapStateToProps = ({ auth }) => {
  const { userInfo } = auth;
  return { userInfo };
};

export default connect(mapStateToProps, { signOut })(NavProfile);
