import HttpClient from "../utils/http-client";
import SharedConstant from "../constants/SharedConstant";

const SharedService = {
  customerList: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(
          `${SharedConstant.CUSTOMER_LIST}`
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  assignedUserList: (userInfo) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(
          `${SharedConstant.ASSIGNED_USER_LIST}?id=${userInfo?.owner_group}&role=${userInfo?.role}`
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  campaignList: (vicidialServerIp, organisation) => {
    return new Promise(async (resolve, reject) => {
      
      try {
        const response = await HttpClient.get(
          `${SharedConstant.CAMPAIGN_LIST}?vicidialServerIp=${vicidialServerIp}&organisation=${organisation}`
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  dispositionList: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(
          `${SharedConstant.DISPOSITION_LIST_URL}`
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  productList: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(
          `${SharedConstant.PRODUCT_LIST_URL}`
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
  bucketList: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.get(
          `${SharedConstant.BUCKET_LIST_URL}`
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
};

export default SharedService;
