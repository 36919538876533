import {
  LOAD_CONTACT_LIST,
  LOAD_CONTACT_TRACE_DATA,
  SET_SELECTED_CONTACT_ROW,
  SET_START_CALL,
  SHOW_DISPOSITION_PANEL,
  HIDE_DISPOSITION_PANEL,
  SET_TOTAL_PAGE_ROWS,
  SHOW_LOADING,
  HIDE_LOADING,
  SET_VICI_CALL_LOG,
  LOAD_DASHBOARD,
  SET_SEARCHED_PARAMS,
  TOGGLE_CONTACT_DETAIL_EDITABLE,
  SET_MODIFIED_CONTACT_DETAIL_DATA,
  UPDATE_CONTACT_TRACE_DATA,
  SET_INBOUND_CALL_DATA,
  SET_CONTACT_INFO_DATA,
  SET_CURRENT_PAGE,
  LOAD_DISPOSITION_LIST,
  LOAD_PRODUCT_LIST,
  LOAD_BUCKET_LIST,
  SET_TODO_LIST,
  SET_MULTIPLE_ACCOUNTS_VISIBLE,
  SET_SEARCH_CALL_TYPE,
  SET_OPENED_DISPO_FORM,
  SET_HANGUP_ACTION_ALERT,
  SET_CURRENT_CALL,
  LOAD_CONTACT_INFO_DATA,
  IS_WHATSAPP_ENABLED
} from "../constants/Crm";

export function loadContactList(contactList) {
  return {
    type: LOAD_CONTACT_LIST,
    contactList,
  };
}

export function loadContactTraceData(traceData) {
  return {
    type: LOAD_CONTACT_TRACE_DATA,
    traceData,
  };
}

export function setSelectedRow(selectedRow) {
  return {
    type: SET_SELECTED_CONTACT_ROW,
    selectedRow,
  };
}

export function setStartCall(callType) {
  return {
    type: SET_START_CALL,
    payload: { callType },
  };
}

export function showDispositionPanel() {
  return {
    type: SHOW_DISPOSITION_PANEL,
  };
}

export function hideDispositionPanel() {
  return {
    type: HIDE_DISPOSITION_PANEL,
  };
}

export function setTotalPageRows(key, total) {
  return {
    type: SET_TOTAL_PAGE_ROWS,
    payload: { key, total },
  };
}

export function setCurrentPage(key, page, key2, pageSize) {
  return {
    type: SET_CURRENT_PAGE,
    payload: { key, page, key2, pageSize },
  };
}

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

export const setViciCallLog = (viciCallLog) => {
  return {
    type: SET_VICI_CALL_LOG,
    viciCallLog,
  };
};

export const loadDashoard = (dashboard) => {
  return {
    type: LOAD_DASHBOARD,
    dashboard,
  };
};

export const setSearchedParams = (searchedParams) => {
  return {
    type: SET_SEARCHED_PARAMS,
    searchedParams,
  };
};

export const hideLoading = () => {
  return {
    type: HIDE_LOADING,
  };
};

export const toggleContactDetailEditable = (status) => {
  return {
    type: TOGGLE_CONTACT_DETAIL_EDITABLE,
    status,
  };
};

export const setModifiedContactDetailData = (data) => {
  return {
    type: SET_MODIFIED_CONTACT_DETAIL_DATA,
    data,
  };
};

export const updateContactTraceData = (data) => {
  return {
    type: UPDATE_CONTACT_TRACE_DATA,
    data,
  };
};

export const setInboundCallData = (data) => {
  return {
    type: SET_INBOUND_CALL_DATA,
    data,
  };
};

export const setContactInfo = (data) => {
  return {
    type: SET_CONTACT_INFO_DATA,
    data,
  };
};

export const loadContactInfo = (data) => {
  return {
    type: LOAD_CONTACT_INFO_DATA,
    data,
  };
};

export const loadDispositionList = (list) => {
  return {
    type: LOAD_DISPOSITION_LIST,
    list,
  };
};

export const loadProductList = (list) => {
  return {
    type: LOAD_PRODUCT_LIST,
    list,
  };
};

export const loadBucketList = (list) => {
  return {
    type: LOAD_BUCKET_LIST,
    list,
  };
};

export const loadTodoList = (list) => {
  return {
    type: SET_TODO_LIST,
    list,
  };
};

export const setMultipleAccountsVisible = (status, data) => {
  return {
    type: SET_MULTIPLE_ACCOUNTS_VISIBLE,
    payload: {
      status,
      data,
    },
  };
};

export const setSearchCallType = (searchCallType) => {
  return {
    type: SET_SEARCH_CALL_TYPE,
    searchCallType,
  };
};

export const setOpenedDispoForm = (openedDispoForm) => {
  return {
    type: SET_OPENED_DISPO_FORM,
    openedDispoForm,
  };
};

export const setHangUpActionAlert = (hangUpActionAlert) => {
  return {
    type: SET_HANGUP_ACTION_ALERT,
    hangUpActionAlert,
  };
};

export const setCurrentCall = (currentCall) => {
  return {
    type: SET_CURRENT_CALL,
    currentCall,
  };
};

export const setIsWhatsappEnabled = (status) => {
  return {
    type: IS_WHATSAPP_ENABLED,
    status,
  };
};