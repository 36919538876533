import React from "react";
import utils from "utils";

export const checkNumericValue = (event) => {
  if (isNaN(event.key) || event.key === " ") {
    event.preventDefault();
  }
};

export const checkPhoneNumber = (event, id) => {
  let doc = document.getElementById(id);
  if (doc.selectionStart === 0 && (event.key === "0" || event.key === 0)) {
    event.preventDefault();
  } else if (!/^[0-9]*$/.test(event.key)) {
    event.preventDefault();
  }
};

export const getSocketEndPoint = () => {
  let SOCKET_URL;
  if (window.location.hostname === "localhost") {
    SOCKET_URL = `http://${window.location.hostname}:3003`;
  } else {
    SOCKET_URL = `https://${window.location.hostname}`;
  }
  return SOCKET_URL;
};

export const disableFutureDate = (current) => {
  return current && current > new Date();
};

export const getDispositionList = (dispositionlist) => {
  const list = [];
  dispositionlist.forEach((value, key) => {
    list.push({
      value: key,
      label: value.disposition,
      id: value.rank,
      color: value.color,
      icon: value.icon,
      subDisposition: value.subDisposition,
    });
  });

  return list;
};

export const getSubDisposition = (sel_disposition, dispositionlist) => {
  let sub_list;
  dispositionlist.forEach((value, key) => {
    if (key === sel_disposition) return (sub_list = value.sub_disposition);
  });
  return sub_list;
};

export const masking = (data) => {
  let last_digits,
    masked_number = "";
  if (data && data !== "undefined" && data !== "null") {
    if (typeof data === "number") {
      data = data.toString();
    }
    data = data.trim();
    if (data.length === 0) return "";
    let input_len = data.length;
    last_digits = data.substring(input_len - 4, input_len);
    for (let idx = 0; idx < data.length - 4; idx++) {
      masked_number += "*";
    }
    masked_number += last_digits;
    return masked_number;
  } else {
    return "";
  }
};

export const channelOptions = [
  {
    label: "All Channels",
    value: "all",
  },
  {
    label: "Calling",
    value: "Spocto-CRM",
  },
  {
    label: "IVR",
    value: "ivr",
  },
  {
    label: "SMS",
    value: "sms",
  },
];

export const configureOptions = [
  {
    label: "Last 1 Month (Default)",
    value: "30",
  },
  {
    label: "Last 2 Months",
    value: "60",
  },
  {
    label: "Last 3 Months",
    value: "90",
  },
  {
    label: "Last 15 Days",
    value: "15",
  },
  {
    label: "Last 7 Days",
    value: "7",
  },
];

export const getDashboardCardColor = [
  "#08a74d",
  "#40af3d",
  "#6fb62e",
  "#8cba24",
  "#b4c017",
  "#d2c50e",
  "#dfc709",
  "#f3cb04",
  "#f3c10b",
  "#f3bb10",
  "#f3bc0f",
  "#f3b118",
  "#f3a424",
  "#f3972f",
  "#F38F39",
  "#ee8536",
  "#eb7f35",
  "#e77635",
  "#db6c35",
  "#dc5f34",
  "#d55233",
  "#d14a33",
  "#ce4332",
  "#cb3d32",
  "#bf2531",
  "#ff0000",
];

export const columns = [
  {
    title: "Date & Time",
    dataIndex: "date_time",
    render: (_, record) => <>{utils.getFormatedDateTime(record.date_time)}</>,
  },
  {
    title: "Channel",
    dataIndex: "channel",
    render: (_, record) => <>{record.channel === 'Spocto-CRM' ? 'Calling': record.channel}</>,
  },
  {
    title: "Disposition",
    dataIndex: "disposition",
  },
  {
    title: "Sub-disposition",
    dataIndex: "sub_disposition",
  },
];

export const settlementColumns = [
  {
    title: "Date & Time",
    dataIndex: "datetime",
    render: (_, record) => <>{utils.getFormatedDateTime(record.datetime)}</>,
  },
  {
    title: "Settlement Action",
    dataIndex: "sub_disposition",
  },
  {
    title: "AssignerID",
    dataIndex: "assigner_id",
  },
];

export const cibilColumns = [
  {
    title: "Date & Time",
    dataIndex: "datetime",
    render: (_, record) => <>{utils.getFormatedDateTime(record.datetime)}</>,
  },
  {
    title: "Cibil Cleanup Action",
    dataIndex: "sub_disposition",
  },
  {
    title: "AssignerID",
    dataIndex: "assigner_id",
  },
];

export const agentAssignmentMapping = [
  "customerid",
  "batch_no",
  "loan_account_no",
  "assigned_user",
  "disposition",
];

export const connectDataMapping = [
  "customerid",
  "loan_account_no",
  "spocto_phone_1",
  "spocto_phone_2",
  "spocto_phone_3",
  "spocto_phone_4",
  "spocto_phone_1_cc",
  "spocto_phone_2_cc",
  "spocto_phone_3_cc",
  "spocto_phone_4_cc",
];
