import HttpClient from "../utils/http-client";
import DataAdministrationConstant from "constants/DataAdministrationConstant";

const DataAdministrationService = {
  getAllocationDetails: (values) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HttpClient.post(
          `${DataAdministrationConstant.ALLOCATION_DETAILS}`,
          values
        );
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },
};

export default DataAdministrationService;
