import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Drawer,
  Menu,
  Button,
  Form,
  Col,
  Row,
  Input,
  message,
  Select,
  Tooltip,
} from "antd";
import { DynamicInput } from "components/shared-components/DynamicInput";
import { connect } from "react-redux";
import {
  showDispositionPanel,
  hideDispositionPanel,
  showLoading,
  hideLoading,
  setViciCallLog,
  setStartCall,
  setOpenedDispoForm,
  setHangUpActionAlert,
} from "redux/actions/Crm";
import CrmService from "services/CrmService";
import {
  ARE_MULTIPLE_ACCOUNTS_VISIBLE,
  IS_CALL_ACTIVE,
} from "redux/constants/Crm";
import DispositionDropdown from "components/util-components/Crm/DispositionDropdown";
import { getDispositionList, getSubDisposition } from "utils/shared";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";

const NavPanel = ({
  userInfo,
  isDispositionPanelVisible,
  showDispositionPanel,
  hideDispositionPanel,
  showLoading,
  hideLoading,
  setStartCall,
  startCall,
  inboundCallData,
  dispositionlist,
  setOpenedDispoForm,
  hangUpActionAlert,
  setHangUpActionAlert,
  currentCall,
  contactTraceData,
}) => {
  const [input, setInput] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [requiredFields, setRequiredFields] = useState([]);
  const [selectedDisposition, setSelectedDisposition] = useState([]);

  const [form] = Form.useForm();
  const cibil_amount =
    userInfo?.regionConfig?.currency === "AED" ? "AECB Amount" : "Cibil Amount";

  const defaultValues = {
    name: contactTraceData?.full_name
      ? contactTraceData.full_name
      : "unregistered_user",
    account: contactTraceData?.loan_account_no
      ? contactTraceData.loan_account_no
      : "unregistered_loan_account_no",
    caller: userInfo ? `${userInfo.firstname} ${userInfo.lastname} ` : null,
  };

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  const onFinish = () => {
    setSubmitLoading(true);
    setOpenedDispoForm(false);
    form
      .validateFields()
      .then((selected_value) => {
        const values = {
          ...selected_value,
          disposition: selected_value.disposition.split("$")[0],
          rank: selected_value.disposition.split("$")[1],
          ptp_committed_date:
            selected_value?.ptp_committed_date &&
            moment(selected_value.ptp_committed_date).format("YYYY-MM-DD"),
        };

        CrmService.disposeCall(
          userInfo,
          contactTraceData,
          values,
          startCall,
          inboundCallData
        )
          .then((res) => {
            message.success(res);
            form.resetFields();
            hideDispositionPanel();
            setSubmitLoading(false);
            setStartCall(false);
            sessionStorage.removeItem(IS_CALL_ACTIVE); //For hangup Button Remove
            sessionStorage.removeItem(ARE_MULTIPLE_ACCOUNTS_VISIBLE);
            setInput([]);
            setRequiredFields([]);
          })
          .catch((err) => {
            message.error(err);
            form.resetFields();
            setSubmitLoading(false);
          });
      })
      .catch((e) => {
        setSubmitLoading(false);
        message.error("Please enter all required field ");
      });
  };

  const onDispositionChange = (selected_value) => {
    const value = selected_value.split("$")[0];
    let sub_list = getSubDisposition(value, dispositionlist);
    setSelectedDisposition(sub_list);
    form.setFieldsValue({ sub_disposition: "" });

    if (["CALL BACK/REVISIT", "PAY LATER"].includes(value)) {
      setRequiredFields(["sub_disposition"]);
      setInput([
        {
          type: "datetime",
          name: "ptp_committed_date",
          label: "Follow-Up",
          disabledPastDate: true,
          rule: [{ required: true, message: "Please enter follow up date !" }],
        },
      ]);
    } else if (
      [
        "DEATH",
        "LANGUAGE BARRIER",
        "REFUSE TO PAY",
        "SITUATIONAL NOT CONTACTABLE",
        "NEVER USED NEVER PAID",
        "CALL DISCONNECTED",
        "INVALID",
      ].includes(value)
    ) {
      setRequiredFields(["sub_disposition"]);
      setInput([]);
    } else if (value === "LEFT MESSAGE") {
      setInput([
        {
          type: "datetime",
          name: "ptp_committed_date",
          label: "Follow-Up",
          disabledPastDate: true,
          rule: [{ required: true, message: "Please enter follow up date !" }],
        },
      ]);
    } else if (
      ["DISPUTE", "STOP FOLLOW UP", "CLOSED", "ASSET SURRENDER"].includes(value)
    ) {
      setRequiredFields(["description"]);
      setInput([]);
    } else if (
      [
        "FULLY PAID (Confirmed by bank)",
        "PARTIALLY PAID (Confirmed by bank)",
      ].includes(value)
    ) {
      setInput([
        {
          type: "date",
          name: "payment_date",
          label: "Payment Date",
          rule: [{ required: true, message: "Please enter payment date !" }],
          disabledFutureDate: true,
        },
        {
          type: "text",
          name: "payment_amount",
          label: "Payment Amount",
          rule: [
            {
              required: true,
              message: "Please enter payment amount!",
            },
            {
              pattern: /^\d+(\.\d{1,2})?$/,
              message: "Please enter amount upto 2 decimal places !",
            },
          ],
        },
        {
          type: "select",
          name: "payment_mode",
          label: "Payment Mode",
          rule: [{ required: true, message: "Please enter payment mode !" }],
          options: [
            "NetBanking",
            "Mobile Banking",
            "Cash",
            "Cheque",
            "Card",
            "UPI",
            "Field Agent",
          ],
        },
        {
          type: "text",
          name: "reference_number",
          label: "Reference Number",
          rule: [
            { required: true, message: "Please enter reference number !" },
          ],
        },
      ]);
    } else if (value === "CLAIMS PAID") {
      setRequiredFields(["sub_disposition"]);
      setInput([
        {
          type: "date",
          name: "payment_date",
          label: "Payment Date",
          disabledFutureDate: true,
          rule: [{ required: true, message: "Please enter payment date !" }],
        },
        {
          type: "text",
          name: "payment_amount",
          label: "Payment Amount",
          rule: [
            {
              required: true,
              message: "Please enter payment amount!",
            },
            {
              pattern: /^\d+(\.\d{1,2})?$/,
              message: "Please enter amount upto 2 decimal places !",
            },
          ],
        },
        {
          type: "select",
          name: "payment_mode",
          label: "Payment Mode",
          rule: [{ required: true, message: "Please enter payment mode !" }],
          options: [
            "NetBanking",
            "Mobile Banking",
            "Cash",
            "Cheque",
            "Card",
            "UPI",
            "Field Agent",
          ],
        },
        {
          type: "text",
          name: "reference_number",
          label: "Reference Number",
          rule: [
            { required: true, message: "Please enter reference number !" },
          ],
        },
      ]);
    } else if (value === "PROMISE TO PAY") {
      setInput([
        {
          type: "date",
          name: "ptp_committed_date",
          label: "PTP Committed Date",
          disabledPastDate: true,
          rule: [{ required: true, message: "Please enter committed date !" }],
        },
        {
          type: "text",
          name: "ptp_committed_amount",
          label: "Payment Committed Amount",
          rule: [
            {
              required: true,
              message: "Please enter payment committed amount !",
            },
            {
              pattern: /^\d+(\.\d{1,2})?$/,
              message: "Please enter amount upto 2 decimal places !",
            },
          ],
        },
        {
          type: "select",
          name: "payment_mode",
          label: "Payment Mode",
          rule: [{ required: true, message: "Please enter payment mode !" }],
          options: [
            "NetBanking",
            "Mobile Banking",
            "Cash",
            "Cheque",
            "Card",
            "UPI",
            "Field Agent",
          ],
        },
      ]);
    } else if (value === "BROKEN PROMISE TO PAY") {
      setInput([
        {
          type: "date",
          name: "ptp_committed_date",
          label: "PTP Committed Date",
          rule: [{ required: true, message: "Please enter committed date !" }],
        },
        {
          type: "text",
          name: "ptp_committed_amount",
          label: "Payment Committed Amount",
          rule: [
            {
              required: true,
              message: "Please enter payment committed amount !",
            },
            {
              pattern: /^\d+(\.\d{1,2})?$/,
              message: "Please enter amount upto 2 decimal places !",
            },
          ],
        },
      ]);
    } else if (["CIBIL CLEANUP", "SETTLEMENT"].includes(value)) {
      setRequiredFields(["sub_disposition"]);
      let new_label =
        value === "SETTLEMENT" ? "Settlement Amount" : cibil_amount;
      let input_name =
        value === "SETTLEMENT" ? "settlement_amount" : "cibil_amount";
      setInput([
        {
          type: "date",
          name: "ptp_committed_date",
          label: "PTP Committed Date",
          rule: [{ required: true, message: "Please enter committed date !" }],
        },
        {
          type: "text",
          name: "ptp_committed_amount",
          label: "Payment Committed Amount",
          rule: [
            {
              required: true,
              message: "Please enter payment committed amount !",
            },
            {
              pattern: /^\d+(\.\d{1,2})?$/,
              message: "Please enter amount upto 2 decimal places !",
            },
          ],
        },
        {
          type: "text",
          name: input_name,
          label: new_label,
          rule: [
            {
              required: true,
              message: `Please enter ${new_label} !`,
            },
            {
              pattern: /^\d+(\.\d{1,2})?$/,
              message: "Please enter amount upto 2 decimal places !",
            },
          ],
        },
        {
          type: "text",
          name: "emi_amount",
          label: "EMI Amount",
          rule: [
            {
              pattern: /^\d+(\.\d{1,2})?$/,
              message: "Please enter amount upto 2 decimal places !",
            },
          ],
        },
        {
          type: "number",
          name: "no_of_installments",
          label: "Number of Installments",
          min: 0,
          max: 999,
          rule: [
            { required: true, message: "Please enter no of installments !" },
          ],
        },
        {
          type: "select",
          name: "payment_mode",
          label: "Payment Mode",
          rule: [{ required: true, message: "Please enter payment mode !" }],
          options: [
            "NetBanking",
            "Mobile Banking",
            "Cash",
            "Cheque",
            "Card",
            "UPI",
            "Field Agent",
          ],
        },
      ]);
    } else if (value === "SUPERVISOR FOLLOW UP") {
      setRequiredFields(["description"]);
      setInput([
        {
          type: "datetime",
          name: "ptp_committed_date",
          label: "Follow-Up",
          disabledPastDate: true,
          rule: [{ required: true, message: "Please enter follow up date !" }],
        },
      ]);
    } else {
      setInput([]);
      setRequiredFields([]);
    }
  };
  const list = getDispositionList(dispositionlist);

  const onSubdispositionChange = (value) => {
    if (value == "Payment Details Not Shared") {
      setInput([
        {
          type: "date",
          name: "payment_date",
          label: "Payment Date",
          disabledFutureDate: true,
          rule: [{ required: false }],
        },
        {
          type: "text",
          name: "payment_amount",
          label: "Payment Amount",
          rule: [
            {
              required: false,
            },
            {
              pattern: /^\d+(\.\d{1,2})?$/,
              message: "Please enter amount upto 2 decimal places !",
            },
          ],
        },
        {
          type: "select",
          name: "payment_mode",
          label: "Payment Mode",
          rule: [{ required: false }],
          options: [
            "NetBanking",
            "Mobile Banking",
            "Cash",
            "Cheque",
            "Card",
            "UPI",
            "Field Agent",
          ],
        },
        {
          type: "text",
          name: "reference_number",
          label: "Reference Number",
          rule: [{ required: false }],
        },
      ]);
    } else if (value == "Payment Details Shared") {
      setInput([
        {
          type: "date",
          name: "payment_date",
          label: "Payment Date",
          disabledFutureDate: true,
          rule: [{ required: true, message: "Please enter payment date !" }],
        },
        {
          type: "text",
          name: "payment_amount",
          label: "Payment Amount",
          rule: [
            {
              required: true,
              message: "Please enter payment amount!",
            },
            {
              pattern: /^\d+(\.\d{1,2})?$/,
              message: "Please enter amount upto 2 decimal places !",
            },
          ],
        },
        {
          type: "select",
          name: "payment_mode",
          label: "Payment Mode",
          rule: [{ required: true, message: "Please enter payment mode !" }],
          options: [
            "NetBanking",
            "Mobile Banking",
            "Cash",
            "Cheque",
            "Card",
            "UPI",
            "Field Agent",
          ],
        },
        {
          type: "text",
          name: "reference_number",
          label: "Reference Number",
          rule: [
            { required: true, message: "Please enter reference number !" },
          ],
        },
      ]);
    }
  };

  const onCallHangup = () => {
    showLoading();
    CrmService.hangupCall(userInfo)
      .then((res) => {
        hideLoading();
        if (res.status === 403) {
          setHangUpActionAlert(res.message);
        } else {
          setHangUpActionAlert("Success");
        }
        showDispositionPanel();
        sessionStorage.setItem(IS_CALL_ACTIVE, "Hangup");
        setStartCall("Hangup");
      })
      .catch((err) => {
        message.error(err?.message);
        hideLoading();
        setStartCall("Hangup");
      });
  };

  return (
    <>
      <Menu mode="horizontal">
        <Menu.Item key={0}></Menu.Item>
      </Menu>
      <Drawer
        title={
          <>
            <div className="text-right pb-3">
              {startCall === "Hangup" ? (
                <div className="ant custom-alert-msg">
                  <div className="anticon mx-1">
                    {hangUpActionAlert === "Success" ? (
                      <div className="bg-success-dark success">
                        <div>
                          {" "}
                          <CheckOutlined style={{ fontSize: "9px" }} />{" "}
                        </div>
                      </div>
                    ) : (
                      <div className="bg-error-dark error">
                        <div>
                          {" "}
                          <CloseOutlined style={{ fontSize: "9px" }} />{" "}
                        </div>
                      </div>
                    )}
                  </div>
                  {hangUpActionAlert === "Success"
                    ? "Success! Call Ended Successfully"
                    : hangUpActionAlert}
                </div>
              ) : null}
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <h3>Call Disposition</h3>
              {(startCall === "Inbound" ||
                (startCall === "Outbound" &&
                  currentCall === contactTraceData.id)) && (
                <Button
                  type="primary"
                  className="px-5 btn-outline rounded"
                  // danger
                  onClick={onCallHangup}
                >
                  Hang Up
                </Button>
              )}
            </div>
          </>
        }
        placement="right"
        width={450}
        open={isDispositionPanelVisible}
        closable={
          startCall === "Inbound" || startCall === "Outbound" ? true : false
        }
        onClose={() => {
          hideDispositionPanel();
        }}
        maskClosable={false}
        className="disposition_drawer"
        closeIcon={
          <Tooltip placement="left" title="Close">
            <CloseOutlined />
          </Tooltip>
        }
      >
        <Form
          layout="vertical"
          hideRequiredMark
          form={form}
          initialValues={defaultValues}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="name" label="Name">
                <Input placeholder="Please enter name" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="account"
                label="Account No"
                rules={[{ required: true, message: "Please enter account no" }]}
              >
                <Input placeholder="Please enter account no" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="caller"
                label="Caller"
                initialValue={
                  userInfo ? `${userInfo.firstname} ${userInfo.lastname} ` : ""
                }
              >
                <Input placeholder="Please enter Caller" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="disposition"
                label="Disposition"
                rules={[
                  { required: true, message: "Please Select Disposition" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Disposition"
                  optionFilterProp="label"
                  size="medium"
                  onChange={onDispositionChange}
                >
                  {list.map((elm) => (
                    <Select.Option
                      key={elm.value}
                      label={elm.label}
                      value={`${elm.value}$${elm.id}`}
                    >
                      {elm.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {selectedDisposition && selectedDisposition.length > 0 && (
            <Row gutter={16}>
              <Col span={24}>
                <DispositionDropdown
                  placeholder="Sub-Disposition"
                  label="Sub-Disposition"
                  name="sub_disposition"
                  selectedDisposition={selectedDisposition}
                  rules={[
                    {
                      required: requiredFields.includes("sub_disposition"),
                      message: "Please select sub-disposition",
                    },
                  ]}
                  onChangefunction={onSubdispositionChange}
                />
              </Col>
            </Row>
          )}
          {input.map((elm, i) => {
            return (
              <Row gutter={16} key={elm.name}>
                <Col span={24}>
                  <DynamicInput elm={elm} />
                </Col>
              </Row>
            );
          })}
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: requiredFields.includes("description"),
                    message: "Please enter description",
                  },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Please enter description (max 500 characters)"
                  maxLength={500}
                />
              </Form.Item>
            </Col>
          </Row>
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button
              type="primary"
              onClick={() => onFinish()}
              htmlType="submit"
              style={{ marginRight: 8 }}
              loading={submitLoading}
              className="px-4"
              disabled={startCall === "Hangup" ? false : true}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

const mapStateToProps = ({ crm, auth }) => {
  const {
    isDispositionPanelVisible,
    viciCallLog,
    startCall,
    inboundCallData,
    dispositionlist,
    hangUpActionAlert,
    currentCall,
    contactTraceData,
  } = crm;
  const { userInfo } = auth;
  return {
    userInfo,
    isDispositionPanelVisible,
    viciCallLog,
    startCall,
    inboundCallData,
    dispositionlist,
    hangUpActionAlert,
    currentCall,
    contactTraceData,
  };
};

export default connect(mapStateToProps, {
  showDispositionPanel,
  hideDispositionPanel,
  showLoading,
  hideLoading,
  setViciCallLog,
  setStartCall,
  setOpenedDispoForm,
  setHangUpActionAlert,
})(NavPanel);
