import {
  LOAD_CONTACT_LIST,
  LOAD_CONTACT_TRACE_DATA,
  SET_SELECTED_CONTACT_ROW,
  SET_START_CALL,
  SHOW_DISPOSITION_PANEL,
  SET_TOTAL_PAGE_ROWS,
  SHOW_LOADING,
  HIDE_LOADING,
  HIDE_DISPOSITION_PANEL,
  SET_VICI_CALL_LOG,
  LOAD_DASHBOARD,
  SET_SEARCHED_PARAMS,
  TOGGLE_CONTACT_DETAIL_EDITABLE,
  SET_MODIFIED_CONTACT_DETAIL_DATA,
  UPDATE_CONTACT_TRACE_DATA,
  SET_INBOUND_CALL_DATA,
  SET_CONTACT_INFO_DATA,
  CONTACT_INFO,
  IS_CALL_ACTIVE,
  SET_CURRENT_PAGE,
  LOAD_DISPOSITION_LIST,
  LOAD_PRODUCT_LIST,
  LOAD_BUCKET_LIST,
  SET_TODO_LIST,
  SET_MULTIPLE_ACCOUNTS_VISIBLE,
  SET_SEARCH_CALL_TYPE,
  SET_OPENED_DISPO_FORM,
  SET_HANGUP_ACTION_ALERT,
  SET_CURRENT_CALL,
  LOAD_CONTACT_INFO_DATA,
  IS_WHATSAPP_ENABLED,
} from "../constants/Crm";

const initState = {
  contactList: "",
  selectedContactRow: "",
  contactTraceData: "",
  startCall: sessionStorage.getItem(IS_CALL_ACTIVE),
  isDispositionPanelVisible:
    sessionStorage.getItem(IS_CALL_ACTIVE) === "Hangup" ? true : false,
  loading: false,
  totalPageRows: {
    contactList: 20,
  },
  currentPage: {
    contactList: 1,
    contactListSize: 20,
  },
  viciCallLog: "",
  dashboard: new Map(),
  dispositionlist: new Map(),
  productlist: [],
  bucketlist: [],
  searchedParams: {},
  isContactDetailEditable: false,
  editedContactDetail: "",
  inboundCallData: "",
  todoList: [],
  multipleAccountsVisible: false,
  multipleAccountsData: [],
  contactInfo: sessionStorage.hasOwnProperty(CONTACT_INFO)
    ? JSON.parse(atob(sessionStorage.getItem(CONTACT_INFO)))
    : {
        id: "",
        loan_account_no: "",
        batch_no: "",
        customerid: "",
        index: "",
      },
  searchCallType: "Outbound",
  openedDispoForm: false,
  hangUpActionAlert: "",
  currentCall: parseInt(sessionStorage.getItem("currentCall")),
  isWhatsappEnabled: false,
};

const crm = (state = initState, action) => {
  switch (action.type) {
    case LOAD_CONTACT_LIST:
      return {
        ...state,
        contactList: action.contactList,
      };
    case LOAD_CONTACT_TRACE_DATA:
      return {
        ...state,
        contactTraceData: action.traceData,
        loading: false,
      };
    case SET_SELECTED_CONTACT_ROW:
      return {
        ...state,
        selectedContactRow: action.selectedRow,
      };
    case SET_START_CALL: {
      return {
        ...state,
        startCall: action.payload.callType,
      };
    }
    case SHOW_DISPOSITION_PANEL: {
      return {
        ...state,
        isDispositionPanelVisible: true,
      };
    }
    case HIDE_DISPOSITION_PANEL: {
      return {
        ...state,
        isDispositionPanelVisible: false,
      };
    }
    case SET_TOTAL_PAGE_ROWS: {
      const { key, total } = action.payload;
      return {
        ...state,
        totalPageRows: { ...state.totalPageRows, [key]: total },
      };
    }
    case SET_CURRENT_PAGE: {
      const { key, page, key2, pageSize } = action.payload;
      return {
        ...state,
        currentPage: { ...state.currentPage, [key]: page, [key2]: pageSize },
      };
    }
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case HIDE_LOADING: {
      return {
        ...state,
        loading: false,
      };
    }
    case SET_VICI_CALL_LOG: {
      return {
        ...state,
        viciCallLog: action.viciCallLog,
      };
    }
    case LOAD_DASHBOARD: {
      const dashboardData = action.dashboard ? action.dashboard : [];
      const dashboardMap = new Map(state.dispositionlist);

      for (let i = 0; i < dashboardData.length; i++) {
        if (dashboardMap.get(dashboardData[i].value)) {
          const data = {
            ...dashboardMap.get(dashboardData[i].value),
            ...dashboardData[i],
          };
          dashboardMap.set(dashboardData[i].value, data);
        } else if (dashboardData[i].value === "Total") {
          dashboardMap.set("Total", { ...dashboardData[i] });
        }
      }

      return {
        ...state,
        dashboard: dashboardMap,
      };
    }

    case TOGGLE_CONTACT_DETAIL_EDITABLE: {
      return {
        ...state,
        isContactDetailEditable: action.status,
      };
    }
    case SET_MODIFIED_CONTACT_DETAIL_DATA: {
      return {
        ...state,
        editedContactDetail: action.data,
      };
    }
    case SET_SEARCHED_PARAMS: {
      let params;
      const searchParams =
        action.searchedParams !== "Total" ? action.searchedParams : "";
      if (action.searchedParams.constructor === String) {
        params = {
          disposition: searchParams,
        };
      } else if (
        Object.keys(action.searchedParams).length === 0 &&
        action.searchedParams.constructor === Object
      ) {
        params = searchParams;
      } else {
        params = searchParams;
      }
      return {
        ...state,
        searchedParams: params,
      };
    }
    case UPDATE_CONTACT_TRACE_DATA:
      return {
        ...state,
        contactTraceData: { ...state.contactTraceData, ...action.data },
      };
    case SET_INBOUND_CALL_DATA:
      return {
        ...state,
        inboundCallData: action.data,
      };
    case SET_CONTACT_INFO_DATA:
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          ...action.data,
        },
      };
    case LOAD_CONTACT_INFO_DATA:
      return {
        ...state,
        contactInfo: action.data,
      };
    case LOAD_DISPOSITION_LIST:
      const dispositionMap = new Map();
      action.list &&
        action.list.forEach((_disposition) => {
          dispositionMap.set(_disposition.disposition, {
            ..._disposition,
          });
        });
      return {
        ...state,
        dispositionlist: dispositionMap,
        //dashboard: dispositionMap,
      };

    case LOAD_PRODUCT_LIST: {
      return {
        ...state,
        productlist: action.list,
      };
    }

    case LOAD_BUCKET_LIST: {
      return {
        ...state,
        bucketlist: action.list,
      };
    }

    case SET_TODO_LIST: {
      return {
        ...state,
        todoList: action.list,
      };
    }
    case SET_MULTIPLE_ACCOUNTS_VISIBLE: {
      return {
        ...state,
        multipleAccountsVisible: action.payload.status,
        multipleAccountsData: action.payload.data,
      };
    }
    case SET_SEARCH_CALL_TYPE: {
      return {
        ...state,
        searchCallType: action.searchCallType,
      };
    }
    case SET_OPENED_DISPO_FORM: {
      return {
        ...state,
        openedDispoForm: action.openedDispoForm,
      };
    }
    case SET_HANGUP_ACTION_ALERT: {
      return {
        ...state,
        hangUpActionAlert: action.hangUpActionAlert,
      };
    }
    case SET_CURRENT_CALL: {
      return {
        ...state,
        currentCall: action.currentCall,
      };
    }
    case IS_WHATSAPP_ENABLED: {
      return {
        ...state,
        isWhatsappEnabled: action.status,
      };
    }

    default:
      return state;
  }
};

export default crm;
