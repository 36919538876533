let BASE_URL;
if (window.location.hostname === "localhost") {
  BASE_URL = `http://localhost:3003/api`;
} else {
  BASE_URL = `/api`;
}

const DataAdministrationConstant = {
  ALLOCATION_DETAILS: `${BASE_URL}/data-administration/allocation-details`,
  DOWNLOAD_ALLOCATION: `${BASE_URL}/data-administration/download-allocation`,
  DOWNLOAD_AGENT_FILE: `${BASE_URL}/data-administration/download-agent-file`,
};

export default DataAdministrationConstant;
