import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  AUTH_TOKEN,
  SIGNIN,
  SIGNOUT,
  VICIDIAL_LOGIN,
  IS_VICI_LOGGEDIN,
  VICIDIAL_USER_INFO,
} from "../constants/Auth";
import { LOGIN_EVENT_ID } from "redux/constants/User";
import {
  showAuthMessage,
  authenticated,
  signOutSuccess,
  vicidialAuthenticated,
} from "../actions/Auth";
import { AuthService, UserService } from "services";
import {
  encryptData,
  giveCallingAccess,
} from "utils/user-manage";
import { SMARTDIAL_PUBLIC } from "redux/constants/Crm";

export function* signInWithEmail() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    try {
      const authToken = yield call(AuthService.signin, payload);
      if (authToken) {
        sessionStorage.setItem(AUTH_TOKEN, authToken.authToken);
        const user = yield call(AuthService.loginWithToken);

        if (user.message) {
          yield put(showAuthMessage(user.message));
        } else {
          yield put(authenticated(user));

          //Aaina Log User login Time
          const id = yield call(UserService.logUserEvent, {
            userInfo : user?.userInfo,
            eventName: "Login",
            id: "",
            logTime: "Start",
          });
          if (id) {
            sessionStorage.setItem(LOGIN_EVENT_ID, id);
          }
        }
      }
    } catch (err) {
      yield put(showAuthMessage(err ? err.message : "Invalid Email/password"));
    }
  });
}

export function* signInToVicidial() {
  yield takeEvery(VICIDIAL_LOGIN, function* ({ payload }) {
    try {
      const vici = yield call(AuthService.vicidialLogin, payload);
      const { phone_pass, pass, server_ip, ...rest } = vici;
      const vicidialInfo = { ...rest, campaign: payload.campaign };
      if (vici.message) {
        yield put(showAuthMessage(vici.message));
      } else {
        yield put(vicidialAuthenticated(vicidialInfo));
        if (giveCallingAccess(payload.userInfo.role) && vici?.server_ip) {
          yield put(vicidialAuthenticated(vicidialInfo));

            window.open(
              `http://${vici.server_ip}/agc/vicidial.php?phone_login=${vici.phone_login}&phone_pass=${vici.phone_pass}&VD_login=${vici.user}&VD_pass=${vici.pass}&VD_campaign=${payload.campaign}`
            );
        
          sessionStorage.setItem(SMARTDIAL_PUBLIC, encryptData(vici.server_ip));
          sessionStorage.setItem(
            VICIDIAL_USER_INFO,
            JSON.stringify(vicidialInfo)
          );
          sessionStorage.setItem(IS_VICI_LOGGEDIN, false);
        } else {
          let server_ip = payload.userInfo.server_ip;
          if(payload?.userInfo?.organisation && payload.userInfo.organisation === 'Allset'){

            window.open(
              `http://${server_ip}/crm_iframe/main_panel.php`
            );

            sessionStorage.setItem(SMARTDIAL_PUBLIC, encryptData(server_ip));
            sessionStorage.setItem(
              VICIDIAL_USER_INFO,
              JSON.stringify({ campaign: payload.campaign, method: "MANUAL"})
            );
            sessionStorage.setItem(IS_VICI_LOGGEDIN, false);
          }
          else{
            sessionStorage.setItem(IS_VICI_LOGGEDIN, true);
            yield put(showAuthMessage("User Not Configured  SmartDial"));
          }
          
        }
      }
    } catch (err) {
      yield put(showAuthMessage(err ? err.message : "SmartDial Login Failed"));
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* ({ payload }) {
    try {
      const loginEventId = sessionStorage.getItem(LOGIN_EVENT_ID);
      //Aaina Log User logout Time
      if (loginEventId) {
        yield call(UserService.logUserEvent, {
          eventName: "Login",
          id: loginEventId,
          logTime: "End",
        });
      }
      yield call(AuthService.viciLogout, payload);

      sessionStorage.clear();
      localStorage.clear();
      yield put(signOutSuccess());
    } catch (err) {
      sessionStorage.clear();
      localStorage.clear();
      yield put(signOutSuccess());
    }
  });
}

export default function* rootSaga() {
  yield all([fork(signInWithEmail), fork(signOut), fork(signInToVicidial)]);
}
