export const LOAD_CONTACT_LIST = "LOAD_CONTACT_LIST";
export const LOAD_CONTACT_TRACE_DATA = "LOAD_CONTACT_TRACE_DATA";
export const SET_SELECTED_CONTACT_ROW = "SET_SELECTED_CONTACT_ROW";
export const SET_START_CALL = "SET_START_CALL";
export const SHOW_DISPOSITION_PANEL = "SHOW_DISPOSITION_PANEL";
export const HIDE_DIPOSITION_PANEL = "HIDE_DIPOSITION_PANEL";
export const SET_TOTAL_PAGE_ROWS = "SET_TOTAL_PAGE_ROWS";
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";
export const HIDE_DISPOSITION_PANEL = "HIDE_DISPOSITION_PANEL";
export const SET_VICI_CALL_LOG = "SET_VICI_CALL_LOG";
export const LOAD_DASHBOARD = "LOAD_DASHBOARD";
export const SET_DASHBOARD_FILTER = "SET_DASHBOARD_FILTER";
export const SET_SEARCHED_PARAMS = "SET_SEARCHED_PARAMS";
export const TOGGLE_CONTACT_DETAIL_EDITABLE = "TOGGLE_CONTACT_DETAIL_EDITABLE";
export const SET_MODIFIED_CONTACT_DETAIL_DATA =
  "SET_MODIFIED_CONTACT_DETAIL_DATA";
export const UPDATE_CONTACT_TRACE_DATA = "UPDATE_CONTACT_TRACE_DATA";
export const SET_INBOUND_CALL_DATA = "SET_INBOUND_CALL_DATA";
export const IS_CALL_ACTIVE = "IS_CALL_ACTIVE";
export const SET_CONTACT_INFO_DATA = "SET_CONTACT_INFO_DATA";
export const CONTACT_INFO = "CONTACT_INFO";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const LOAD_DISPOSITION_LIST = "LOAD_DISPOSITION_LIST";
export const LOAD_PRODUCT_LIST = "LOAD_PRODUCT_LIST";
export const LOAD_BUCKET_LIST = "LOAD_BUCKET_LIST";
export const SMARTDIAL = "SMARTDIAL";
export const SMARTDIAL_PUBLIC = "SMARTDIAL_PUBLIC";
export const SET_TODO_LIST = "SET_TODO_LIST";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const SET_MULTIPLE_ACCOUNTS_VISIBLE = "SET_MULTIPLE_ACCOUNTS_VISIBLE";
export const SET_SEARCH_CALL_TYPE = "SET_SEARCH_CALL_TYPE";
export const ARE_MULTIPLE_ACCOUNTS_VISIBLE = "ARE_MULTIPLE_ACCOUNTS_VISIBLE";
export const SET_OPENED_DISPO_FORM = "SET_OPENED_DISPO_FORM";
export const SET_HANGUP_ACTION_ALERT = "SET_HANGUP_ACTION_ALERT";
export const SET_CURRENT_CALL = 'SET_CURRENT_CALL';
export const LOAD_CONTACT_INFO_DATA = 'LOAD_CONTACT_INFO_DATA';
export const IS_WHATSAPP_ENABLED = 'IS_WHATSAPP_ENABLED';
export const SEND_WHATSAPP_URL = 'SEND_WHATSAPP_URL';

