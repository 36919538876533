import { Modal } from "antd";

export const info = (title, content) => {
  Modal.info({
    title: title,
    content: content,
    onOk() {},
  });
};

export const success = (title, content) => {
  Modal.success({
    title: title,
    content: content,
    onOk() {},
  });
};

export const error = (content) => {
  Modal.error({
    title: "Error!",
    content: content,
  });
};

export const warning = () => {
  Modal.warning({
    title: "This is a warning message",
    content: "some messages...some messages...",
  });
};
