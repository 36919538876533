import {
  LOAD_ASSIGNED_USER_LIST,
  SET_USER_ACTIVITY_STATUS,
  LOGIN_EVENT_ID,
  BREAK_EVENT_ID,
  SET_BREAK_EVENT_ID,
  SET_USER_ASSIGNED_CUSTOMER_DETAIL,
  UPDATE_ASSIGNED_USER_LIST,
} from "../constants/User";

const initState = {
  loading: false,
  assignedUserList: "",
  userAssignedCustomerDetail: "",
  userActivityStatus: "active",
  loginEventId: sessionStorage.getItem(LOGIN_EVENT_ID),
  breakEventId: sessionStorage.getItem(BREAK_EVENT_ID),
};

const user = (state = initState, action) => {
  switch (action.type) {
    case LOAD_ASSIGNED_USER_LIST:
      return {
        ...state,
        assignedUserList: action.assignedList,
      };
    case UPDATE_ASSIGNED_USER_LIST:
      const assignedList = action.list;
      return {
        ...state,
        assignedUserList: [...state.assignedUserList, assignedList],
      };
    case SET_USER_ACTIVITY_STATUS: {
      return {
        ...state,
        userActivityStatus: action.status,
      };
    }
    case SET_USER_ASSIGNED_CUSTOMER_DETAIL: {
      let filteredList = [];
      if (
        (action.payload.role === "Agent" || action.payload.role === "Inbound Agent") && action.payload.customerDetail && 
        action.payload.customerDetail.length !== 0 
      ) {
        if (action.payload.customerid)
        {
          filteredList = action.payload && action.payload.customerDetail.filter((elm) => {
            return action.payload.customerid.includes(elm.customer_id);
          });
        }
        else
        {
          filteredList = []
        }
      } else {
        filteredList = action.payload.customerDetail;
      }

      return {
        ...state,
        userAssignedCustomerDetail: filteredList,
      };
    }
    case SET_BREAK_EVENT_ID: {
      return {
        ...state,
        breakEventId: action.breakEventId,
      };
    }
    default:
      return state;
  }
};

export default user;
