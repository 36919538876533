import {
  AUTH_TOKEN,
  AUTHENTICATED,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNOUT_SUCCESS,
  SHOW_LOADING,
  VICIDIAL_AUTHENTICATED,
  IS_VICI_LOGGEDIN,
  LOAD_CUSTOMER_LIST,
  VICIDIAL_USER_INFO,
  CLOSE_VICIDIAL_POPUP,
  UPDATE_USER_INFO,
  LOAD_USER_PROFILE,
} from "../constants/Auth";

const initState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  userInfo: "",
  token: sessionStorage.getItem(AUTH_TOKEN),
  vicidialUserInfo: JSON.parse(sessionStorage.getItem(VICIDIAL_USER_INFO)),
  isViciLoggedIn: sessionStorage.hasOwnProperty(IS_VICI_LOGGEDIN)
    ? JSON.parse(sessionStorage.getItem(IS_VICI_LOGGEDIN))
    : true,
  customerList: "",
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        loading: false,
        redirect: "/",
        token: action.user.authToken,
        userInfo: action.user.userInfo,
      };
    case LOAD_USER_PROFILE:
      return {
        ...state,
        userInfo: action.userInfo,
        heading: action.heading,
        text: action.text,
      };
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false,
      };
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: "",
        showMessage: false,
      };
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        redirect: "/",
        loading: false,
        isViciLoggedIn: true,
      };
    }
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case VICIDIAL_AUTHENTICATED: {
      return {
        ...state,
        vicidialUserInfo: action.viciUserInfo,
        isViciLoggedIn: false,
      };
    }
    case LOAD_CUSTOMER_LIST: {
      return {
        ...state,
        customerList: action.customerList,
      };
    }
    case CLOSE_VICIDIAL_POPUP: {
      return {
        ...state,
        isViciLoggedIn: typeof(action.status) != Boolean && false ,
      };
    }
    case UPDATE_USER_INFO: {
      return {
        ...state,
        userInfo: action.userInfo,
      };
    }
    default:
      return state;
  }
};

export default auth;
