import axios from "axios";
import { AUTH_TOKEN } from "../redux/constants/Auth";

//Axios Interceptor to add token in Header:
axios.interceptors.request.use(
  async (config) => {
    const token = sessionStorage.getItem(AUTH_TOKEN);
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// API respone interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let notificationParam = {
      message: "",
    };

    if (error?.response?.status === 401) {
      notificationParam.message = "Token Expired";
      notificationParam.description = "Please login again";
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }

    if (error?.response?.status === 404) {
      notificationParam.message = "Not Found";
    }

    if (error?.response?.status === 500) {
      notificationParam.message = "Internal Server Error";
    }

    if (error?.response?.status === 508) {
      notificationParam.message = "Time Out";
    }

    return Promise.reject(error);
  }
);

const HttpClient = {
  get: (url) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(url);
        resolve(response);
      } catch (error) {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error.request.data);
        } else {
          reject(error);
        }
      }
    });
  },
  post: (url, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(url, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        resolve(response);
      } catch (error) {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error.request.data);
        } else {
          reject(error);
        }
      }
    });
  },
  put: (url, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.put(url, data);
        resolve(response);
      } catch (error) {
        if (error.response) {
          reject(error.response.data);
        } else if (error.request) {
          reject(error.request.data);
        } else {
          reject(error);
        }
      }
    });
  },
};

export default HttpClient;
