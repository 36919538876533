import CrmService from "services/CrmService";
import ReportsService from "services/ReportsService";
import SharedService from "services/SharedService";
import UserService from "services/UserService";
import AuthService from "services/AuthService";
import DataAdministrationService from "./DataAdministrationService";

export {
  CrmService,
  ReportsService,
  SharedService,
  UserService,
  AuthService,
  DataAdministrationService,
};
